<template>
  <div>
    <van-popup
      v-model="show"
      :getContainer="
        () => {
          $refs.body;
        }
      "
      position="left"
      :style="{ width }"
      @close="close"
    >
      <div class="project_box">
        <div class="project_head">
          <p>{{ dataForm.is_divide == 1 ? "复习项目" : "训练项目" }}</p>
          <span @click="close">
            <van-icon name="cross" />
          </span>
        </div>
        <div class="project_content">
          <div class="divide_box" v-if="dataForm.is_divide == 1">
            <el-form :inline="true" :model="dataForm" class="demo-form-inline">
              <template v-if="$route.query.subject == 1">
                <el-form-item label="领域">
                  <el-select
                    v-model="dataForm.manor_id"
                    :popper-append-to-body="false"
                    placeholder="请选择"
                    @change="firstChange"
                  >
                    <el-option
                      v-for="item in firstOption"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="维度">
                  <el-select
                    v-model="dataForm.main_id"
                    :popper-append-to-body="false"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in secondOption"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-if="$route.query.subject == 2">
                <el-form-item label="程度">
                  <el-select
                    v-model="dataForm.degree_id"
                    :popper-append-to-body="false"
                    placeholder="请选择"
                    @change="firstChange"
                  >
                    <el-option
                      v-for="item in firstOption"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="模块">
                  <el-select
                    v-model="dataForm.module_id"
                    :popper-append-to-body="false"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in secondOption"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
              <el-form-item class="btn">
                <el-button type="primary" @click="getList">搜索</el-button>
              </el-form-item>
            </el-form>
            <div class="scroll">
              <div class="item" v-for="item in list" :key="item.id" @click="projectChange(item)">
                <div
                  class="item_title"
                  v-if="$route.query.subject == 1"
                >{{ item.manor_name + ">" + item.main_name }}</div>
                <div
                  class="item_title"
                  v-else-if="$route.query.subject == 2"
                >{{ item.degre_name + ">" + item.module_name }}</div>
                <div
                  class="item_content"
                  :class="{
                    day_trained: item.other_tea == 1,
                    tea_trained: item.own_tea == 1,
                  }"
                >{{ item.project_name }}</div>
              </div>
              <div class="zw" v-for="(i, ind) in 10" :key="'zw' + ind"></div>
            </div>
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.page"
              :page-size="pagination.page_size"
              background
              layout="total, prev, pager, next"
              :total="pagination.totalCount"
            ></el-pagination>
          </div>
          <div class="scroll" v-else>
            <div class="item" v-for="item in list" :key="item.id" @click="projectChange(item)">
              <div
                class="item_title"
                v-if="$route.query.subject == 1"
              >{{ item.manor_name + ">" + item.main_name }}</div>
              <div
                class="item_title"
                v-else-if="$route.query.subject == 2"
              >{{ item.degree_name + ">" + item.module_name }}</div>
              <div
                class="item_content"
                :class="{
                  day_trained: item.other_tea == 1,
                  tea_trained: item.own_tea == 1,
                }"
              >{{ item.project_name }}</div>
            </div>
            <div class="zw" v-for="(i, ind) in 10" :key="ind"></div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import "@/plugin/element";
export default {
  props: ["width"],
  data() {
    return {
      show: false,
      dataForm: {},
      list: [],
      firstOption: [],
      secondOption: [],
      pagination: {
        page: 1,
        page_size: 20,
        totalCount: 0,
      },
    };
  },
  methods: {
    async getOption() {
      let params = {
        srecord_id: this.$route.query.id,
        subject: this.$route.query.subject,
      };
      const res = await this.$http.get("pad/pad/one-option", {
        params: params,
      });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.firstOption = res.data instanceof Array ? res.data : [];
    },
    async getList() {
      let path = "";
      let params = {};
      if (this.$route.query.subject == 1) {
        path =
          this.dataForm.is_divide == 1
            ? "pad/pad/revise-list"
            : "pad/pad/train-list";
        params =
          this.dataForm.is_divide == 1
            ? {
              id: this.dataForm.id,
              sunhost_id: this.dataForm.sunhost_id,
              manor_id: this.dataForm.manor_id,
              main_id: this.dataForm.main_id,
              page: this.pagination.page,
              page_size: this.pagination.page_size,
            }
            : {
              id: this.dataForm.id,
              sunhost_id: this.dataForm.sunhost_id,
            };
      }
      if (this.$route.query.subject == 2) {
        path =
          this.dataForm.is_divide == 1
            ? "pad/pad/many-revise"
            : "pad/pad/many-train";
        params =
          this.dataForm.is_divide == 1
            ? {
              id: this.dataForm.id,
              manyhost_id: this.dataForm.sunhost_id,
              degree_id: this.dataForm.degree_id,
              module_id: this.dataForm.module_id,
              page: this.pagination.page,
              page_size: this.pagination.page_size,
            }
            : {
              id: this.dataForm.id,
              manyhost_id: this.dataForm.sunhost_id,
            };
      }

      const res = await this.$http.get(path, { params: params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      if (this.dataForm.is_divide == 1) {
        this.list = res.data.list;
        this.pagination.totalCount = Number(res.data.page.totalCount);
      } else {
        this.list = res.data instanceof Array ? res.data : [];
      }
      this.show = true;
    },
    async getSelectList() {
      const res = await this.$http.get(path, { params: params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
    },
    close() {
      this.$emit("projectClose");
    },
    projectChange(e) {
      this.$emit("projectSelect", { ...e, is_divide: this.dataForm.is_divide });
    },
    firstChange(val) {
      if (this.$route.query.subject == 1) {
        this.$set(this.dataForm, "main_id", "");
      }
      if (this.$route.query.subject == 2) {
        this.$set(this.dataForm, "module_id", "");
      }
      this.secondOption = this.firstOption.filter(
        (e) => e.id == val
      )[0].son_list;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/deep/ .van-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}
/deep/ .van-popup {
  border-radius: 0 0.2rem 0 0;
  top: 0.73rem;
  bottom: 0;
  transform: translate3d(0, 0, 0);
  height: calc(100vh - 0.73rem);
}
.project_head {
  height: 0.42rem;
  line-height: 0.42rem;
  padding: 0 0.14rem;
  background: url("~@/assets/img/form_head.png") -0.1rem top/102% 100% no-repeat;
  p {
    font-size: 0.16rem;
    color: #750100;
    text-align: center;
  }
  span {
    position: absolute;
    top: 0;
    right: 0.14rem;
    font-size: 0.16rem;
    color: #750100;
  }
}
.project_content {
  padding: 0.16rem 0.25rem 0.3rem;
  height: calc(100vh - 1.15rem);
  // display: flex;
  .scroll {
    overflow-y: scroll;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
  }
  .item {
    border-radius: 0.08rem;
    margin-bottom: 0.16rem;
    width: 2.24rem;
    height: 0.96rem;
    background: #f1f1f1;
    padding: 0 0.08rem 0.1rem;
    overflow: hidden;
    .item_title {
      line-height: 0.26rem;
      font-size: 0.12rem;
      color: #580100;
    }
    .item_content {
      height: 0.6rem;
      background: #fff;
      padding: 0.05rem 0.13rem;
      font-size: 0.14rem;
      color: #333;
      border-radius: 0.06rem;
      line-height: 0.24rem;
      overflow: hidden;
      &.day_trained {
        position: relative;
        &::after {
          content: "✓";
          display: block;
          width: 0.15rem;
          height: 0.15rem;
          position: absolute;
          top: 0;
          right: 0;
          background: #ffd9d9;
          border-radius: 0 0 0 0.2rem;
          font-size: 0.1rem;
          text-align: right;
          line-height: 0.15rem;
          color: #f14947;
        }
      }
      &.tea_trained {
        color: #ff5f4c;
      }
    }
  }
  .zw {
    width: 2.24rem;
    height: 0;
  }
  .divide_box {
    /deep/ .el-form-item {
      background: #f5f5f5;
      padding: 0 0.15rem;
      border-radius: 0.06rem;
      margin-right: 0.2rem;
      .el-form-item__label {
        font-size: 0.16rem;
      }
      .el-select {
        width: 1.3rem;
      }
      .el-input__inner {
        background: none;
        border: none;
        text-align: right;
      }
      .el-select-dropdown {
        position: absolute !important;
        .el-select-dropdown__item {
          padding: 0 0.2rem;
          &.selected {
            color: #f14947;
          }
        }
      }
      &.btn {
        background: #f14947;
        padding: 0 0.26rem;
        .el-button--primary {
          background: none;
          border: none;
          span {
            font-size: 0.16rem;
          }
        }
      }
    }
    .scroll {
      height: calc(100vh - 2.5rem);
      margin-top: 0.22rem;
    }
    /deep/ .el-pagination {
      text-align: right;
      .el-pager li:not(.disabled).active {
        background: #f14947;
      }
    }
  }
}
</style>