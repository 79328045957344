<template>
  <div>
    <div class="class_page">
      <div class="header">
        <div class="left" @click="$router.replace('/index')">
          <van-icon name="wap-home" />
        </div>
        <div class="info">
          <ul>
            <li v-if="$route.query.subject == 1">姓名:{{ $route.query.u_name }}</li>
            <li v-else>小组:{{ $route.query.g_name }}</li>
            <li>课程:{{ $route.query.les_name }}</li>
            <li>上课时间:{{ $route.query.poin_time }}</li>
            <li>上课教室:{{ $route.query.room_name }}</li>
            <li v-if="$route.query.ass_name">辅助老师:{{ $route.query.ass_name }}</li>
          </ul>
        </div>
        <div class="btn">
          <div v-if="courStatus === 0" @click="startClass">开始上课</div>
          <div v-if="courStatus === 1" @click="endClass">完成本次课程</div>
        </div>
      </div>
      <div class="body" ref="body">
        <div class="previewList" v-if="courStatus === 0">
          <ul>
            <li
              v-for="item in PreList"
              :key="item.only"
              :class="{ cur: active == item.only }"
              @click="getDetail(item)"
            ><span class="van-multi-ellipsis--l2">{{ item.project_name }}</span></li>
          </ul>
        </div>
        <div class="tags" v-if="courStatus === 1 && !toolShow">
          <div class="btn" @click="projectShowHide(0)">训练项目</div>
          <div class="btn" @click="projectShowHide(1)">复习项目</div>
        </div>
        <project-list
          v-if="projectShow"
          width="74%"
          ref="projectmodal"
          @projectClose="projectShowHide"
          @projectSelect="projectSelect"
        ></project-list>
        <div class="main" :class="{ pre: courStatus === 0 }">
          <div class="details" v-if="!toolShow">
            <div class="scroll">
              <div class="head">
                <span>{{ typeTxt }}</span>
                <span class="title" :style="{ fontSize: courStatus === 0 ? '0.13rem' : '0.16rem' }">
                  {{
                  detail.project_name
                  ? ["", detail.main_name, detail.module_name][
                  $route.query.subject
                  ] +
                  "-" +
                  detail.project_name
                  : ""
                  }}
                </span>
                <span class="tool" @click="getTool">教具素材</span>
              </div>
              <div class="basic_info">
                <div class="result_info" v-if="detail.is_divide == 1">
                  <span class="time">
                    项目通过时间：{{
                    $stampToTime(detail.a_time, "YYYY-MM-DD HH:mm")
                    }}
                  </span>
                  <span class="tea">训练老师：{{ detail.tea_name }}</span>
                </div>
                <div class="list">
                  <div
                    class="empty"
                    v-if="
                      ($route.query.subject == 1 &&
                        detail.list &&
                        detail.list.length == 0) ||
                      ($route.query.subject == 2 &&
                        detail.mrec_list &&
                        detail.mrec_list.length == 0)
                    "
                  >
                    <template v-if="detail.is_divide == 1">
                      <p>
                        <van-empty description="暂无复习记录" image-size="75px" />
                      </p>
                    </template>
                    <template v-else>
                      <p
                        v-if="
                          $route.query.subject == 1 &&
                          (detail.teach_type == 1 || detail.teach_type == 2)
                        "
                      >首次训练项目需先进行3次大试探</p>
                      <p v-else>
                        <van-empty description="暂无训练记录" image-size="75px" />
                      </p>
                    </template>
                  </div>
                  <div class="not_empty" v-else>
                    <div class="divide_data" v-if="detail.is_divide == 1">
                      <div class="top">
                        <p>项目复习记录</p>
                      </div>
                      <div class="content">
                        <ul>
                          <li v-for="item in detail.threeData" :key="item.id">
                            <p class="time">
                              项目复习时间：{{
                              $stampToTime(item.r_time, "YYYY-MM-DD HH:mm")
                              }}
                            </p>
                            <p class="tea">复习老师：{{ item.tea_name }}</p>
                            <p class="result">复习结果：{{ ["N", "Y"][item.is_adopt] }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="train_data" v-else>
                      <div class="onetomany" v-if="$route.query.subject == 2">
                        <div class="top">
                          <div>
                            <p>
                              上次课训练记录：{{
                              detail.threeData &&
                              $stampToTime(
                              detail.threeData[0].train_time,
                              "YYYY-MM-DD HH:mm"
                              )
                              }}
                            </p>
                            <p>
                              通过率：{{
                              detail.threeData &&
                              detail.threeData[0].pass_rate
                              }}%
                            </p>
                          </div>
                          <span @click="moreDataShowHide">查看更多</span>
                        </div>
                        <div class="content" v-if="detail.threeData">
                          <ul>
                            <li v-for="item in detail.threeData[0].train" :key="item.uid">
                              <div class="name" :class="{ off: item.status }">{{ item.stu_name }}</div>
                              <div class="is_adopt">{{ ["N", "Y"][item.is_adopt] }}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="gexun"
                        v-else-if="
                          detail.teach_type == 1 || detail.teach_type == 2
                        "
                      >
                        <div class="top">
                          <p>最近三次数据</p>
                          <span @click="moreDataShowHide">查看更多</span>
                        </div>
                        <div class="content">
                          <ul>
                            <li v-for="item in detail.threeData" :key="item.id">
                              <div class="type">
                                {{
                                item.big.length > 0
                                ? "大试探(" +
                                item.big
                                .map((e) => ["N", "Y"][e.key])
                                .join("") +
                                ")"
                                : "小试探(" +
                                item.small
                                .map((e) => ["N", "Y"][e.key])
                                .join("") +
                                ")"
                                }}
                              </div>
                              <div class="items">
                                <div
                                  class="d_item"
                                  v-for="(item1, index1) in item.train"
                                  :key="index1"
                                  :class="{ err: item1.key == 2 }"
                                >
                                  <p v-if="item1.key != 2">{{ item1.txt }}</p>
                                  <van-popover
                                    v-else
                                    v-model="item1.showPopover"
                                    trigger="click"
                                    placement="top"
                                    :closeOnClickAction="false"
                                    :getContainer="
                                      () => {
                                        $refs.body;
                                      }
                                    "
                                  >
                                    <div class="pop">
                                      <div
                                        class="pop_item"
                                        v-for="(item2, index2) in item1.list"
                                        :key="index2"
                                      >{{ item2.txt }}</div>
                                    </div>
                                    <template #reference>
                                      <p>{{ item1.txt }}</p>
                                    </template>
                                  </van-popover>
                                </div>
                              </div>
                              <div class="is_now" v-if="item.is_now == 1">
                                <i class="el-icon-check"></i>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="zhineng"
                        v-else-if="
                          detail.teach_type == 3 || detail.teach_type == 4
                        "
                      >
                        <div class="top">
                          <p>训练记录</p>
                          <span @click="moreDataShowHide">查看全部数据</span>
                        </div>
                        <div class="content">
                          <ul>
                            <li v-for="item in detail.threeData" :key="item.id">
                              <p class="tg">目标：{{ item.target }}</p>
                              <p class="tea">训练老师：{{ item.tea_name }}</p>
                              <p class="sta">
                                目标完成情况：{{
                                ["", "独立完成", "半辅", "全辅"][
                                item.train[0].key
                                ]
                                }}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="edict"
                v-if="
                  $route.query.subject == 1 &&
                  (detail.teach_type == 1 || detail.teach_type == 2) &&
                  detail.is_divide != 1
                "
              >
                <div class="title">
                  <span>指令</span>
                </div>
                <div class="con" @click="formOpen(1, detail.edict)">
                  <span>{{ detail.edict }}</span>
                </div>
              </div>
              <div
                class="target"
                v-if="
                  $route.query.subject == 1 &&
                  (detail.teach_type == 3 || detail.teach_type == 4)
                "
              >
                <div class="title">
                  <span>目标</span>
                </div>
                <div class="con" @click="formOpen(3, detail.target)">
                  <div v-if="!detail.target" class="add">
                    <van-icon name="plus" />
                    <span>添加目标</span>
                  </div>
                  <span v-else>{{ detail.target }}</span>
                </div>
              </div>
              <div
                class="notes"
                v-if="
                  $route.query.subject == 1 ||
                  ($route.query.subject == 2 && detail.is_divide == 1)
                "
              >
                <div class="title">
                  <span>备注</span>
                  <span class="more" @click="remarksShowHide" v-if="$route.query.subject == 1">查看更多</span>
                </div>
                <div class="con" @click="formOpen(2, detail.notes)">
                  <span>{{ detail.notes }}</span>
                </div>
              </div>
              <div
                class="notes note_many"
                v-else-if="$route.query.subject == 2 && detail.is_divide == 0"
              >
                <div class="title">
                  <span>备注</span>
                  <span class="name">
                    {{
                    stuSelect && stuSelect.stu_name
                    }}
                  </span>
                </div>
                <div class="con" @click="manyStuNotesOpen">
                  <span
                    v-if="
                      stuSelect &&
                      detail.train.find((e) => e.uid == stuSelect.uid)
                    "
                  >
                    {{
                    detail.train.find((e) => e.uid == stuSelect.uid).notes
                    }}
                  </span>
                  <span v-else-if="stuSelect">{{ stuSelect.content }}</span>
                </div>
              </div>
              <div class="pass_rate" v-if="$route.query.subject == 2 && detail.is_divide == 0">
                <div class="title">
                  <span>通过率</span>
                </div>
                <div
                  class="con"
                  :class="{ not: stuPassRate === '' }"
                >{{ stuPassRate !== "" ? stuPassRate + "%" : "未训练" }}</div>
              </div>
              <div
                class="big"
                v-if="
                  $route.query.subject == 1 &&
                  (detail.teach_type == 1 || detail.teach_type == 2) &&
                  detail.list &&
                  detail.list.length == 0 &&
                  detail.is_divide != 1
                "
              >
                <p>大试探</p>
                <ul>
                  <li
                    class="selected"
                    v-for="(item, index) in bigData"
                    :key="index"
                    :class="{ active: bigIndex === index }"
                    @click="bigEdit(index)"
                  >{{ ["N", "Y"][item.key] }}</li>
                  <li v-if="bigData.length < 3" @click="bigIndex = ''"></li>
                </ul>
                <p>{{ bigStatus }}</p>
              </div>
              <div
                class="big"
                v-if="
                  $route.query.subject == 1 &&
                  (detail.teach_type == 1 || detail.teach_type == 2) &&
                  detail.ado_small == 1 &&
                  smallData.length > 0 &&
                  smallData[0].key == 1 &&
                  detail.is_divide != 1
                "
              >
                <p>小试探</p>
                <p style="flex: 1; text-align: center">通过</p>
              </div>
              <div
                class="modality"
                v-if="
                  $route.query.subject == 1 &&
                  (detail.teach_type == 1 || detail.teach_type == 2) &&
                  !bigOperaShow &&
                  !(
                    detail.ado_small == 1 &&
                    smallData.length > 0 &&
                    smallData[0].key == 1
                  ) &&
                  detail.is_divide != 1
                "
              >
                <p>辅助形式</p>
                <ul>
                  <li
                    v-for="item in modalityOptions"
                    :key="item.value"
                    :class="{ active: modality == item.value }"
                    @click="modalityChange(item)"
                  >{{ item.label + item.symbol }}</li>
                </ul>
              </div>
              <div
                class="train_record"
                v-if="
                  $route.query.subject == 1 &&
                  (detail.teach_type == 1 || detail.teach_type == 2) &&
                  !bigOperaShow &&
                  !(
                    detail.ado_small == 1 &&
                    smallData.length > 0 &&
                    smallData[0].key == 1
                  ) &&
                  detail.is_divide != 1
                "
              >
                <div>
                  <p>训练记录</p>
                  <ul>
                    <li
                      class="selected"
                      v-for="(item, index) in trainData"
                      :key="index"
                      :class="{ active: trainIndex === index }"
                      @click="trainEdit(item, index)"
                    >{{ item.txt }}</li>
                    <li v-if="trainData && trainData.length < 10" @click="trainIndex = ''"></li>
                  </ul>
                  <p style="margin-right: 0.1rem">{{ trainScore }}</p>
                  <span class="backout" @click="backout(trainData, 1)">撤回</span>
                </div>
                <div
                  v-if="
                    $route.query.subject == 1 &&
                    (detail.teach_type == 1 || detail.teach_type == 2) &&
                    errorPertain !== ''
                  "
                  style="margin-top: 0"
                >
                  <p>错误反应</p>
                  <ul>
                    <li
                      class="selected"
                      v-for="(item, index) in trainData[errorPertain].list"
                      :key="index"
                      :class="{ active: errorIndex === index }"
                      @click="errorEdit(index)"
                    >{{ item.txt }}</li>
                    <li v-if="trainData[errorPertain].list.length < 5" @click="errorIndex = ''"></li>
                  </ul>
                  <p style="margin-right: 0.1rem">{{ errorScore }}</p>
                  <span class="backout" @click="backout(trainData[errorPertain].list, 2)">撤回</span>
                </div>
              </div>
              <div
                class="big r"
                :class="{ not: detail.rev_result === '' }"
                v-if="detail.is_divide == 1"
              >
                <p>复习结果</p>
                <ul v-if="detail.rev_result !== ''">
                  <li class="selected">{{ ["N", "Y"][detail.rev_result] }}</li>
                  <p style="margin-left: 0.1rem">{{ ["未通过", "通过"][detail.rev_result] }}</p>
                </ul>
                <div class="con" v-else>未复习</div>
              </div>
              <div class="btns">
                <div class="btn" @click="jump">不训练</div>
                <div class="btn" @click="save">保存并换项目</div>
              </div>
            </div>
          </div>
          <div class="tool_box" v-else>
            <div class="title">
              <div class="back" @click="toolClose">❮返回</div>
              <p>教具素材</p>
              <span></span>
            </div>
            <div class="con">
              <div class="tabs">
                <span :class="{ cur: toolTab == 1 }" @click="toolTab = 1">图片</span>
                <span :class="{ cur: toolTab == 2 }" @click="toolTab = 2">视频/音频</span>
              </div>
              <div class="list">
                <div class="img_box scroll" v-if="toolTab == 1">
                  <div class="item" v-for="(item, index) in toolInfo.tool" :key="index">
                    <div class="name">{{ item.name }}</div>
                    <div class="item_imgs">
                      <div
                        class="image"
                        v-for="(item1, i) in item.img_url"
                        :key="index + i"
                        @click="toolPreview(item1)"
                      >
                        <img :src="item1" alt />
                      </div>
                      <div class="zw" v-for="(ko, i1) in 10" :key="'zw' + i1"></div>
                    </div>
                  </div>
                </div>
                <div class="scroll">
                  <div class="video_box" v-if="toolTab == 2">
                    <div class="item" v-for="(item, index) in toolInfo.video" :key="item.videoId">
                      <template v-if="item.file.indexOf('mp3') !== -1">
                        <div class="audio-player">
                          <!-- <audio src="https://vod.xingxiwang.com/sv/11924976-189aef3a81a/11924976-189aef3a81a.mp3" class="audio"></audio> -->
                          <div class="control-panel">
                            <div v-if="!item.play" class="play-btn" @click="audioClick(item)"></div>
                            <div v-else class="pause-btn" @click="audioClose(item)"></div>
                            <div
                              class="duration"
                              :class="{red:item.duration > 0}"
                            >{{ item.duration < 1 ? '00:00' : $stampToTime(item.duration,'mm:ss') }}</div>
                            <!-- <input type="range" class="progress-bar"/> -->
                            <div class="progress-bar"></div>
                            <div class="total">{{ $stampToTime(item.time,'mm:ss') }}</div>
                          </div>
                        </div>
                        <div class="name">{{ item.name }}</div>
                      </template>
                      <template v-else>
                        <div @click="videoClick(item)">
                          <aliplayer
                            height="1.32rem"
                            barvis="never"
                            :id="index"
                            :vId="item.videoId"
                            :ref="item.videoId"
                            :format="
                              item.file.indexOf('mp3') !== -1 ? 'mp3' : 'mp4'
                            "
                          ></aliplayer>
                          <div class="name">{{ item.name }}</div>
                          <div class="mask">
                            <van-icon name="play-circle" />
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="zw" v-for="(ko, i1) in 10" :key="'zw' + i1"></div>
                  </div>
                </div>
              </div>
              <div class="preview_box" :class="{audio:audioPreview}" v-if="toolPreviewShow">
                <div class="image" v-if="toolTab == 1">
                  <img :src="toolPreviewSrc" alt />
                  <div class="close">
                    <van-icon name="cross" @click="toolPreviewClose" />
                  </div>
                </div>
                <div class="videos" v-if="toolTab == 2">
                  <aliplayer
                    height="3.23rem"
                    autoplay
                    :id="this.$timeTostamp(new Date(), 'YYYY-MM-DD HH:mm:ss')"
                    :vId="toolPreviewVideo.videoId"
                    :ref="toolPreviewVideo.videoId"
                    :format="
                      toolPreviewVideo.file.indexOf('mp3') !== -1
                        ? 'mp3'
                        : 'mp4'
                    "
                  ></aliplayer>
                  <div class="close" @click="videoClose">
                    <van-icon name="cross" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="record">
            <div class="scroll">
              <div class="title">
                <template v-if="detail.is_divide != 1">
                  <p
                    v-if="
                      $route.query.subject == 1 &&
                      (detail.teach_type == 1 || detail.teach_type == 2) &&
                      bigOperaShow
                    "
                  >大试探</p>
                  <p
                    v-if="
                      ($route.query.subject == 1 &&
                        (detail.teach_type == 1 || detail.teach_type == 2) &&
                        this.detail.list &&
                        this.detail.list.length == 0 &&
                        !bigOperaShow) ||
                      $route.query.subject == 2
                    "
                  >训练记录</p>
                  <p
                    v-if="
                      $route.query.subject == 1 &&
                      this.detail.list &&
                      this.detail.list.length > 0 &&
                      (detail.teach_type == 1 || detail.teach_type == 2)
                    "
                  >数据记录</p>
                  <p
                    v-if="
                      $route.query.subject == 1 &&
                      (detail.teach_type == 3 || detail.teach_type == 4)
                    "
                  >目标完成情况</p>
                </template>
                <template v-else>
                  <p>复习记录</p>
                </template>
              </div>
              <div class="operation">
                <template v-if="detail.is_divide != 1">
                  <div
                    class="big_operation"
                    v-if="
                      $route.query.subject == 1 &&
                      (detail.teach_type == 1 || detail.teach_type == 2) &&
                      bigOperaShow
                    "
                  >
                    <div class="btn_box">
                      <div
                        class="btn"
                        :class="{
                          active: bigIndex !== '' && bigData[bigIndex].key == 0,
                        }"
                        @click="bigChange(0)"
                      >N</div>
                      <div
                        class="btn"
                        :class="{
                          active: bigIndex !== '' && bigData[bigIndex].key == 1,
                        }"
                        @click="bigChange(1)"
                      >Y</div>
                    </div>
                  </div>
                  <div
                    class="small_operation"
                    v-if="
                      $route.query.subject == 1 &&
                      this.detail.is_divide == 0 &&
                      this.detail.list.length > 0 &&
                      (detail.teach_type == 1 || detail.teach_type == 2)
                    "
                  >
                    <div class="operation_title">小试探</div>
                    <div class="btn_box">
                      <div
                        class="btn"
                        :class="{
                          active: smallData.length > 0 && smallData[0].key == 0,
                        }"
                        @click="smallChange(0)"
                      >N</div>
                      <div
                        class="btn"
                        :class="{
                          active: smallData.length > 0 && smallData[0].key == 1,
                        }"
                        @click="smallChange(1)"
                      >Y</div>
                    </div>
                  </div>
                  <div
                    class="train_operation"
                    v-if="
                      $route.query.subject == 1 &&
                      (detail.teach_type == 1 || detail.teach_type == 2) &&
                      !bigOperaShow &&
                      !errorShow &&
                      !(
                        detail.ado_small == 1 &&
                        smallData.length > 0 &&
                        smallData[0].key == 1
                      )
                    "
                  >
                    <div class="operation_title">训练记录</div>
                    <div class="btn_box flex_col" v-if="modality == 6">
                      <div
                        class="btn fixed_width"
                        v-for="(item, index) in trainList.bodyAssist"
                        :key="index"
                        :class="{
                          active:
                            trainIndex !== '' &&
                            trainData[trainIndex].key == item.key,
                        }"
                        @click="trainChange(item)"
                      >
                        <span>{{ trainSymbol }}</span>
                        {{ item.symbol }}
                      </div>
                    </div>
                    <div class="btn_box flex_col" v-else>
                      <div
                        class="btn fixed_width"
                        v-for="(item, index) in trainList.otherAssist"
                        :key="index"
                        :class="{
                          active:
                            trainIndex !== '' &&
                            trainData[trainIndex].key == item.key,
                        }"
                        @click="trainChange(item)"
                      >
                        <span>{{ trainSymbol }}</span>
                        {{ item.symbol }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="error_operation"
                    v-if="
                      $route.query.subject == 1 &&
                      (detail.teach_type == 1 || detail.teach_type == 2) &&
                      errorShow
                    "
                  >
                    <div class="operation_title">错误反应</div>
                    <div class="btn_box flex_col">
                      <div
                        class="btn fixed_width"
                        v-for="(item, index) in errorList"
                        :key="index"
                        :class="{
                          active:
                            errorIndex !== '' &&
                            trainData[errorPertain].list[errorIndex].key ==
                              item.key,
                        }"
                        @click="errorChange(item)"
                      >{{ item.symbol }}</div>
                    </div>
                    <div class="confirm_btn" @click="errorSubmit">确定</div>
                  </div>
                  <div
                    class="role_game"
                    v-if="
                      $route.query.subject == 1 &&
                      (detail.teach_type == 3 || detail.teach_type == 4)
                    "
                  >
                    <div class="f">
                      <div class="btn_box flex_col">
                        <div
                          class="btn fwidth_100"
                          v-for="(item, index) in roleGameList"
                          :key="index"
                          :class="{
                            active:
                              trainData.length > 0 &&
                              trainData[0].key == item.key,
                          }"
                          @click="roleGameChange(item.key)"
                        >{{ item.label }}</div>
                      </div>
                    </div>
                    <div class="operation_title">项目状态</div>
                    <div class="btn_box flex_col status_btn">
                      <div
                        class="btn"
                        v-for="(item, index) in roleGameStatus"
                        :key="index"
                        :class="{
                          active: detail.is_train == item.value,
                        }"
                        @click="roleGameStatusChange(item.value)"
                      >{{ item.label }}</div>
                    </div>
                  </div>
                  <div class="many_operation" v-if="$route.query.subject == 2">
                    <div class="scroll">
                      <ul>
                        <li v-for="(item, index) in detail.stu_list" :key="item.uid">
                          <div
                            class="name"
                            :class="{ active: item.show, off: item.status }"
                            @click="stuClick(item)"
                          >
                            {{ item.stu_name }}
                            {{
                            detail.train.find((e) => e.uid == item.uid) &&
                            detail.train.find((e) => e.uid == item.uid)
                            .is_adopt !== ""
                            ? "(" +
                            ["N", "Y"][
                            detail.train.find((e) => e.uid == item.uid)
                            .is_adopt
                            ] +
                            ")"
                            : ""
                            }}
                          </div>
                          <div class="stu_btns btn_box" v-if="item.show">
                            <div
                              class="btn"
                              :class="{
                                active:
                                  detail.train.find(
                                    (e) => e.uid == stuSelect.uid
                                  ) &&
                                  detail.train.find(
                                    (e) => e.uid == stuSelect.uid
                                  ).is_adopt === 0,
                              }"
                              @click="manyStuChange(item, index, 0)"
                            >N</div>
                            <div
                              class="btn"
                              :class="{
                                active:
                                  detail.train.find(
                                    (e) => e.uid == stuSelect.uid
                                  ) &&
                                  detail.train.find(
                                    (e) => e.uid == stuSelect.uid
                                  ).is_adopt === 1,
                              }"
                              @click="manyStuChange(item, index, 1)"
                            >Y</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="big_operation">
                    <div class="btn_box">
                      <div
                        class="btn"
                        :class="{
                          active: detail.rev_result === 0,
                        }"
                        @click="detail.rev_result = 0"
                      >N</div>
                      <div
                        class="btn"
                        :class="{
                          active: detail.rev_result === 1,
                        }"
                        @click="detail.rev_result = 1"
                      >Y</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <edit-form v-if="formShow" width="4.6rem" ref="formmodal" @formClose="formClose"></edit-form>
      <remarks v-if="remarksShow" width="6.8rem" ref="remarksmodal" @remarksClose="remarksShowHide"></remarks>
      <more-data v-if="moreDataShow" ref="moredatamodal" @moreDataClose="moreDataShowHide"></more-data>
      <many-stu-notes
        v-if="manyStuNotesShow"
        width="4.6rem"
        ref="manystunotesmodal"
        @formClose="manyStuNotesClose"
      ></many-stu-notes>
    </div>
  </div>
</template>

<script>
import aliplayer from "@/components/aliplaychange";
import editForm from "./components/form.vue";
import remarks from "./components/remarks.vue";
import moreData from "./components/moreData.vue";
import ProjectList from "./components/projectList.vue";
import manyStuNotes from "./components/manyStuNotes.vue";
export default {
  data() {
    return {
      courStatus: "",
      PreList: [],
      active: "",
      detail: {},
      sunhost_id: "",
      typeTxt: "",
      toolShow: false,
      toolTab: 1,
      toolInfo: {},
      toolPreviewSrc: "",
      toolPreviewVideo: {},
      toolPreviewShow: false,
      formShow: false,
      remarksShow: false,
      bigData: [],
      bigIndex: "",
      bigOperaShow: false,
      bigStatus: "",
      trainData: [],
      trainIndex: "",
      modalityOptions: [
        { label: "位置辅助", symbol: "P", value: 1 },
        { label: "视觉辅助", symbol: "V", value: 2 },
        { label: "示范辅助", symbol: "D", value: 3 },
        { label: "手势辅助", symbol: "G", value: 4 },
        { label: "眼表辅助", symbol: "O", value: 5 },
        { label: "身体辅助", symbol: "S", value: 6 },
      ],
      modality: "",
      trainSymbol: "",
      trainList: {
        bodyAssist: [
          { symbol: "+", score: 10, key: 1 },
          {
            symbol: "-",
            score: "",
            key: 2,
            list: [
              { symbol: "N+", score: 10, key: 7 },
              { symbol: "-NP", score: 0.9, key: 8 },
              { symbol: "-NP²", score: 2.7, key: 9 },
              { symbol: "-NS", score: 6.3, key: 10 },
              { symbol: "ND", score: 8.1, key: 11 },
            ],
          },
          { symbol: "-P", score: 0.9, key: 3 },
          { symbol: "-P²", score: 2.7, key: 4 },
          { symbol: "S", score: 5.4, key: 5 },
          { symbol: "D", score: 8.1, key: 6 },
        ],
        otherAssist: [
          { symbol: "+", score: 10, key: 1 },
          {
            symbol: "-",
            score: "",
            key: 2,
            list: [
              { symbol: "N+", score: 10, key: 7 },
              { symbol: "-NP", score: 1, key: 8 },
              { symbol: "-NP²", score: 3, key: 9 },
              { symbol: "-NP³", score: 6, key: 10 },
              { symbol: "ND", score: 9, key: 11 },
            ],
          },
          { symbol: "-P", score: 1, key: 3 },
          { symbol: "-P²", score: 3, key: 4 },
          { symbol: "-P³", score: 6, key: 5 },
          { symbol: "D", score: 9, key: 6 },
        ],
      },
      errorShow: false,
      errorList: [],
      errorPertain: "",
      errorIndex: "",
      projectShow: false,
      smallData: [],
      moreDataShow: false,
      roleGameList: [
        { label: "独立完成", key: 1 },
        { label: "半辅", key: 2 },
        { label: "全辅", key: 3 },
      ],
      roleGameStatus: [
        { label: "通过", value: 1 },
        { label: "未通过", value: 0 },
      ],
      manyStuNotesShow: false,
      audioPreview: false,
      timer: "",
      audioInfo: {}
    };
  },
  components: {
    aliplayer,
    editForm,
    remarks,
    ProjectList,
    moreData,
    manyStuNotes,
  },
  created() {
    this.getCourStatus().then(() => this.getPreList());
  },
  methods: {
    async getCourStatus() {
      const res = await this.$http.get("pad/pad/cour-status", {
        params: { id: this.$route.query.id },
      });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.courStatus = Number(res.data.status);
      this.sunhost_id = res.data.id;
    },
    async getPreList(appoint) {
      const res = await this.$http.get(
        ["", "pad/pad/have-class", "pad/pad/many-class"][
        Number(this.$route.query.subject)
        ],
        {
          params: { id: this.$route.query.id },
        }
      );
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.PreList = res.data instanceof Array ? res.data : [];
      if (this.PreList.length > 0 && !appoint) {
        // console.log("自动");
        let arr = this.PreList.filter((e) => e.is_record == 0);
        this.active = arr.length > 0 ? arr[0].only : this.PreList[0].only;
        this.getDetail(arr.length > 0 ? arr[0] : this.PreList[0]);
      }
    },
    // 获取项目详情
    async getDetail(e) {
      //   console.log(e);
      let path = "";
      let params = {};
      switch (this.$route.query.subject) {
        case "1":
          path = "pad/pad/plan-get";
          params = {
            make_project: e.make_project,
            is_divide: e.is_divide,
            sunhost_id: this.sunhost_id,
            item_id: e.item_id,
            plan_id: e.plan_id,
            plandetail_id: e.plandetail_id,
            uid: e.uid
          };
          break;
        case "2":
          path = "pad/pad/many-get";
          params = {
            id: e.id,
            mproject_id: e.mproject_id,
            group_id: e.group_id,
            extent: e.extent,
            is_divide: e.is_divide,
            manyhost_id: this.sunhost_id,
            srecord_id: this.$route.query.id,
          };
          break;

        default:
          break;
      }
      const res = await this.$http.get(path, { params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.detail = res.data;
      this.active = e.only;
      this.typeTxt = ["训练项目", "复习项目"][e.is_divide];
      if (this.$route.query.subject == 1) {
        this.bigOperaShow = res.data.list.length > 0 ? false : true;
        this.bigData = res.data.big;
        this.smallData = res.data.small;
        this.modality = Number(res.data.modality);
        this.trainSymbol =
          this.modality == 0
            ? ""
            : this.modalityOptions.find((e) => e.value == this.modality).symbol;
        this.detail.threeData = res.data.list.slice(0, 3);
      }
      if (this.$route.query.subject == 2) {
        this.detail.threeData = res.data.mrec_list.slice(0, 1);
        this.detail.stu_list.map((o, i) => {
          this.$set(o, "show", i == 0 ? true : false);
        });
      }
      this.trainData = res.data.train;
      if (this.detail.is_divide != 1) {
        this.detail.threeData.map((i) => {
          return i.train.map((o) => {
            if (o.key == 2) {
              this.$set(o, "showPopover", false);
            }
          });
        });
      }
      // console.log(this.detail.threeData);
    },
    async startClass() {
      const stime = parseInt(this.$route.query.stime * 1 - 5 * 60);
      if (this.$timeTostamp(new Date(), "YYYY-MM-DD HH:mm:ss") < stime) {
        return this.$vantnotify("未到上课时间");
      }
      const res = await this.$http.get("pad/pad/check-plan", {
        params: { id: this.$route.query.id },
      });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      if (res.data.status != 1) {
        return this.$vantnotify('日计划中无训练项目，请重新备课')
      }
      this.$dialog
        .confirm({
          title: "提示",
          message: "开始上课前，请您准备好教具和强化物品！",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
          closeOnClickOverlay: true,
        })
        .then(async () => {
          let params = {
            srecord_id: this.$route.query.id,
            status: 1,
            tearecord_id: this.$route.query.tearecord_id
          };
          const res = await this.$http.post(
            ["", "bk-sunhost/add", "bk-manyhost/add"][
            Number(this.$route.query.subject)
            ],
            params
          );
          if (res.code * 1 !== 1) {
            return this.$vantnotify(res.msg);
          }
          let arr = this.PreList.filter((e) => e.is_record == 0);
          this.active = arr.length > 0 ? arr[0].only : this.PreList[0].only;
          this.getCourStatus();
          this.getDetail(arr.length > 0 ? arr[0] : this.PreList[0]);
        })
        .catch(() => { });
    },
    endClass() {
      console.log("完成本次课程");
      if (this.$route.query.subject == 1) {
        this.$router.push({
          name: "submitPreview",
          query: {
            ...this.$route.query,
            sunhost_id: this.sunhost_id,
            teach_type: this.detail.teach_type,
          },
        });
      }
      if (this.$route.query.subject == 2) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "结束本次课程后无法修改数据记录结果，是否确认？",
            confirmButtonColor: "#fff",
            messageAlign: "left",
            getContainer: ".body",
            closeOnClickOverlay: true,
          })
          .then(async () => {
            let params = {
              manyhost_id: this.sunhost_id,
            };
            const res = await this.$http.post("bk-manyhost/submit", params);
            if (res.code * 1 !== 1) {
              return this.$vantnotify(res.msg);
            }
            this.$router.replace("/index");
          })
          .catch(() => { });
      }
    },
    async getTool() {
      let item = this.PreList.filter((e) => e.only == this.active)[0]
      let make_project = this.PreList.filter((e) => e.only == this.active)[0]
        .make_project;
      let mproject_id = this.PreList.filter((e) => e.only == this.active)[0]
        .mproject_id;
      let path = "";
      let params = {};
      switch (this.$route.query.subject) {
        case "1":
          path = "pad/pad/plan-tool";
          params = {
            // make_project,
            item_id: item.item_id,
            // plan_id: item.plan_id,
            // plandetail_id: item.plandetail_id,
            uid: item.uid,
            project_id: item.project_id
          };
          break;
        case "2":
          path = "pad/pad/many-tool";
          params = { mproject_id };
          break;

        default:
          break;
      }

      const res = await this.$http.get(path, { params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.toolInfo = res.data;
      this.toolInfo.video = res.data.video ? res.data.video.map((e) => {
        const a = e.name.split(".");
        const file = a.pop();
        return { ...e, name: a.join("."), file: file, play: false, duration: 0 };
      }) : [];
      this.toolShow = true;
    },
    toolClose() {
      if (this.audioPreview) {
        this.audioClose(this.audioInfo)
      }
      this.toolShow = false;
      this.toolInfo = {};
      this.toolTab = 1;
    },
    toolPreview(e) {
      this.toolPreviewSrc = e;
      this.toolPreviewShow = true;
    },
    toolPreviewClose() {
      this.toolPreviewSrc = "";
      this.toolPreviewShow = false;
    },
    videoClick(e) {
      if (this.audioPreview) {
        this.audioClose(this.audioInfo)
      }
      setTimeout(() => {
        this.toolPreviewShow = true;
        this.toolPreviewVideo = e;
      }, 300)
    },
    videoClose() {
      this.toolPreviewShow = false;
      this.toolPreviewVideo = {};
    },
    formOpen(type, content) {
      this.formShow = true;
      this.$nextTick(() => {
        this.$refs.formmodal.dataForm = {
          type,
          content,
        };
        this.$refs.formmodal.init();
      });
    },
    formClose(val) {
      if (val) {
        switch (val.type) {
          case 1:
            this.detail.edict = val.content;
            break;
          case 2:
            this.detail.notes = val.content;
            break;
          case 3:
            this.detail.target = val.content;
            break;

          default:
            break;
        }
      }
      this.formShow = false;
    },
    manyStuNotesOpen() {
      this.manyStuNotesShow = true;
      let flag = this.detail.train.some((e) => e.uid == this.stuSelect.uid);
      let content = flag
        ? this.detail.train.find((e) => e.uid == this.stuSelect.uid).notes
        : this.stuSelect.content;
      this.$nextTick(() => {
        this.$refs.manystunotesmodal.dataForm = {
          content,
        };
        this.$refs.manystunotesmodal.init();
      });
    },
    manyStuNotesClose(val) {
      if (val) {
        let flag = this.detail.train.some((e) => e.uid == this.stuSelect.uid);
        if (!flag) {
          this.detail.train.push({
            uid: this.stuSelect.uid,
            stu_name: this.stuSelect.stu_name,
            status: this.stuSelect.status,
            is_adopt: "",
            notes: val.content,
          });
        } else {
          this.detail.train.find((e) => e.uid == this.stuSelect.uid).notes =
            val.content;
        }
      }
      this.manyStuNotesShow = false;
    },
    remarksShowHide() {
      this.remarksShow = !this.remarksShow;
      if (this.remarksShow) {
        this.$nextTick(() => {
          this.$refs.remarksmodal.dataForm = {
            uid: this.$route.query.uid,
            is_divide: this.detail.is_divide,
            make_project: this.detail.make_project,
            review_id: this.$route.query.review_id,
            sunhost_id: this.sunhost_id,
            sunadopt_id: this.detail.sunadopt_id,
            item_id: this.detail.item_id,
            plan_id: this.detail.plan_id,
            plandetail_id: this.detail.plandetail_id,
          };
          this.$refs.remarksmodal.getList();
        });
      }
    },
    moreDataShowHide() {
      this.moreDataShow = !this.moreDataShow;
      if (this.moreDataShow) {
        this.$nextTick(() => {
          this.$refs.moredatamodal.dataForm = {
            tableData: [[], this.detail.list, this.detail.mrec_list][
              this.$route.query.subject
            ],
            name: this.detail.project_name,
            teach_type: this.detail.teach_type,
          };
          this.$refs.moredatamodal.init();
        });
      }
    },
    projectShowHide(is_divide) {
      this.projectShow = !this.projectShow;
      if (this.projectShow) {
        this.$nextTick(() => {
          this.$refs.projectmodal.dataForm = {
            id: this.$route.query.id,
            sunhost_id: this.sunhost_id,
            is_divide: is_divide,
          };
          if (is_divide == 1) {
            this.$refs.projectmodal.getOption();
          }
          this.$refs.projectmodal.getList();
        });
      }
    },
    bigChange(val) {
      if (this.bigData.length > 2 && this.bigIndex === "") {
        return this.$toast("大试探仅可记录3次！");
      }
      if (this.bigData.length < 3 && this.bigIndex === "") {
        this.bigData.push({ key: val });
      }
      if (this.bigIndex !== "") {
        this.bigData[this.bigIndex].key = val;
        this.bigIndex = "";
      }
      if (this.bigData.length > 2) {
        let bigN = this.bigData.filter((e) => e.key == 0).length;
        this.bigStatus = ["通过", "半辅", "全辅", "全辅"][bigN];
        this.bigOperaShow = bigN !== 0 ? false : true;
      }
    },
    bigEdit(index) {
      if (this.trainData.length > 0) {
        return this.$toast("已有常规记录 不能修改大试探");
      }
      this.bigIndex = index;
      this.bigOperaShow = true;
    },
    modalityChange(e) {
      this.modality = e.value;
      this.trainSymbol = e.symbol;
    },
    trainChange(e) {
      if (!this.modality) {
        return this.$vantnotify("请先选择辅助形式");
      }
      if (
        this.detail.is_divide == 0 &&
        this.detail.list.length > 0 &&
        this.smallData.length == 0 &&
        (this.detail.teach_type == 1 || this.detail.teach_type == 2)
      ) {
        return this.$vantnotify("请先记录小试探");
      }
      if (this.trainData.length > 9 && this.trainIndex === "") {
        return this.$toast("训练记录仅可记录10次!");
      }
      if (e.key == 2) {
        this.errorList = e.list;
        this.errorShow = true;
      }
      if (this.trainData.length < 10 && this.trainIndex === "") {
        this.trainData.push({
          key: e.key,
          modality: this.modality,
          txt: this.trainSymbol + e.symbol,
          score: e.score,
        });
        if (e.key == 2) {
          this.errorPertain = this.trainData.length - 1;
          this.$set(this.trainData[this.errorPertain], "list", []);
        }
      }
      if (this.trainIndex !== "") {
        this.$set(this.trainData, this.trainIndex, {
          key: e.key,
          modality: this.modality,
          txt: this.trainSymbol + e.symbol,
          score: e.score,
        });
        if (e.key == 2) {
          this.errorPertain = this.trainIndex;
          this.$set(this.trainData[this.errorPertain], "list", []);
        }
        this.trainIndex = "";
      }
    },
    trainEdit(e, index) {
      let activeModal = this.modalityOptions.filter(
        (e) => e.value == this.trainData[index].modality
      )[0];
      this.modalityChange(activeModal);
      this.trainIndex = index;
      if (e.key == 2) {
        console.log(e);
        this.errorList =
          e.modality == 6
            ? this.trainList.bodyAssist.filter((i) => i.key == 2)[0].list
            : this.trainList.otherAssist.filter((i) => i.key == 2)[0].list;
        this.errorShow = true;
        this.errorPertain = index;
        this.errorIndex = "";
      } else {
        this.errorList = {};
        this.errorShow = false;
        this.errorPertain = "";
        this.errorIndex = "";
      }
    },
    backout(arr, type) {
      if (type == 1) {
        this.trainIndex = "";
      }
      switch (type) {
        case 1:
          this.trainIndex = "";
          break;
        case 2:
          this.errorIndex = "";
          break;
        default:
          break;
      }
      arr.pop();
    },
    errorChange(e) {
      let arr = this.trainData[this.errorPertain].list;
      if (arr.length > 4 && this.errorIndex === "") {
        return this.$toast("错误反应仅可记录5次!");
      }
      if (this.errorIndex !== "") {
        this.$set(arr, this.errorIndex, {
          key: e.key,
          txt: e.symbol,
          score: e.score,
        });
      } else {
        arr.push({
          key: e.key,
          txt: e.symbol,
          score: e.score,
        });
      }
    },
    errorEdit(index) {
      this.errorIndex = index;
    },
    errorSubmit() {
      if (this.trainData[this.errorPertain].list.length > 0) {
        this.trainData[this.errorPertain].score = this.errorScore;
      } else {
        this.trainData.splice(this.errorPertain, 1);
      }
      this.errorList = [];
      this.errorShow = false;
      this.errorPertain = "";
      this.errorIndex = "";
      this.trainIndex = "";
    },
    projectSelect(e) {
      let selected = this.PreList.filter(
        (i) => i.id == e.id && i.is_divide == e.is_divide
      )[0] || e;
      this.getDetail(selected);
      this.projectShowHide();
    },
    roleGameChange(val) {
      if (!this.detail.target) {
        return this.$vantnotify("请添加目标");
      }
      this.trainData = [{ key: val }];
    },
    roleGameStatusChange(val) {
      if (this.detail.is_train == val) {
        return;
      }
      if (val != 1) {
        this.detail.is_train = val;
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: "项目通过后该项目进入到复习项目中不再训练",
            confirmButtonColor: "#fff",
            messageAlign: "left",
            getContainer: ".body",
            closeOnClickOverlay: true,
          })
          .then(() => {
            this.detail.is_train = val;
          })
          .catch(() => { });
      }
    },
    stuClick(e) {
      if (e.status != 1) {
        e.show = true;
        let arr = this.detail.stu_list.filter((o) => o.uid != e.uid);
        if (arr.length > 0) {
          arr.map((i) => (i.show = false));
        }
      }
    },
    manyStuChange(row, ind, val) {
      let flag = this.detail.train.some((e) => e.uid == this.stuSelect.uid);
      if (!flag) {
        this.detail.train.push({
          uid: this.stuSelect.uid,
          stu_name: this.stuSelect.stu_name,
          status: this.stuSelect.status,
          is_adopt: val,
          notes: this.stuSelect.content,
        });
      } else {
        this.detail.train.find((e) => e.uid == this.stuSelect.uid).is_adopt =
          val;
      }
      let arr = this.detail.stu_list.filter((v, i) => i > ind && v.status != 1);
      if (arr.length > 0) {
        this.stuClick(arr[0]);
      }
    },
    jump() {
      let curIndex = this.PreList.findIndex((e) => {
        if (this.$route.query.subject == 1) {
          if (e.is_feel == 1 || e.is_oral == 1) {
            return e.project_id == this.detail.project_id && e.is_divide == this.detail.is_divide
          } else {
            return e.plandetail_id == this.detail.plandetail_id && e.is_divide == this.detail.is_divide
          }
        } else {
          return e.mproject_id == this.detail.mproject_id && e.is_divide == this.detail.is_divide
        }
      }
      );
      if (curIndex == this.PreList.length - 1) {
        Object.assign(this.$data, this.$options.data.call(this)); //加这句话就可以清空
        this.getCourStatus()
          .then(() => this.getPreList(true))
          .then(() => this.getDetail(this.PreList[0]));
      } else {
        Object.assign(this.$data, this.$options.data.call(this)); //加这句话就可以清空
        this.getCourStatus()
          .then(() => this.getPreList(true))
          .then(() => this.getDetail(this.PreList[curIndex + 1]));
      }
      // console.log(this);
    },
    async save() {
      if (
        this.$route.query.subject == 1 &&
        this.detail.is_divide == 0 &&
        this.detail.list.length == 0 &&
        this.bigData.length == 0 &&
        (this.detail.teach_type == 1 || this.detail.teach_type == 2)
      ) {
        return this.$vantnotify("请记录大试探");
      }
      if (
        this.$route.query.subject == 1 &&
        this.detail.is_divide == 0 &&
        this.detail.list.length > 0 &&
        this.smallData.length == 0 &&
        (this.detail.teach_type == 1 || this.detail.teach_type == 2)
      ) {
        return this.$vantnotify("请记录小试探");
      }

      if (
        this.$route.query.subject == 1 &&
        this.detail.is_divide == 0 &&
        (this.detail.teach_type == 3 || this.detail.teach_type == 4) &&
        this.trainData.length == 0
      ) {
        return this.$vantnotify("请选择目标完成情况");
      }

      if (
        this.$route.query.subject == 2 &&
        this.detail.is_divide == 0 &&
        this.stuPassRate === ""
      ) {
        return this.$vantnotify("请记录学员训练情况");
      }

      if (this.detail.is_divide == 1 && this.detail.rev_result === "") {
        return this.$vantnotify("请记录学员复习情况");
      }

      // 复习项目还需判断是否记录

      let params = {
        id: this.detail.id,
        is_divide: this.detail.is_divide,
        sunhost_id: this.sunhost_id,
        teach_type: this.detail.teach_type,
        make_project: this.detail.make_project,
        uid: this.$route.query.uid,
        item_id: this.detail.item_id,
        plan_id: this.detail.plan_id,
        plandetail_id: this.detail.plandetail_id,
        sunadopt_id: this.detail.sunadopt_id,
        modality: this.modality,
        big:
          this.bigData && this.bigData.length > 0
            ? JSON.stringify(this.bigData)
            : "", // 传空数组时只能给空字符串？
        small:
          this.smallData && this.smallData.length > 0
            ? JSON.stringify(this.smallData)
            : "",
        train:
          this.trainData && this.trainData.length > 0
            ? JSON.stringify(this.trainData)
            : "",
        is_train: this.detail.is_train,
        edict: this.detail.edict,
        target: this.detail.target,
        notes: this.detail.notes,
        rev_result: this.detail.rev_result,
      };

      let paramsMany = {
        id: this.detail.id,
        is_divide: this.detail.is_divide,
        manyhost_id: this.sunhost_id,
        mproject_id: this.detail.mproject_id,
        train:
          this.detail.train && this.detail.train.length > 0
            ? JSON.stringify(this.detail.train)
            : "",
        pass_rate: this.stuPassRate,
        manyadopt_id: this.detail.manyadopt_id,
        rev_result: this.detail.rev_result,
        notes: this.detail.notes,
      };

      const res = await this.$http.post(
        ["", "bk-sunrecord/add", "bk-manyrecord/add"][
        Number(this.$route.query.subject)
        ],
        [{}, params, paramsMany][Number(this.$route.query.subject)]
      );
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      let obj = {};
      let curIndex = this.PreList.findIndex((e) => {
        if (this.$route.query.subject == 1) {
          if (e.is_feel == 1) {
            return e.project_id == this.detail.project_id && e.is_divide == this.detail.is_divide
          } else {
            return e.plandetail_id == this.detail.plandetail_id && e.is_divide == this.detail.is_divide
          }
        } else {
          return e.mproject_id == this.detail.mproject_id && e.is_divide == this.detail.is_divide
        }
      }
      );
      let after = this.PreList.find((o, i) => i > curIndex && o.is_record != 1);
      let before = this.PreList.find(
        (o, i) => i < curIndex && o.is_record != 1
      );
      if (after) {
        // 切换下一个未记录项目
        obj = after;
      } else if (before) {
        // 如无下一个未记录项目则往回找未记录项目（包括已跳过的项目）
        obj = before;
      } else {
        curIndex == this.PreList.length - 1
          ? (obj = this.PreList[0])
          : (obj = this.PreList[curIndex + 1]);
      }
      //通过this.$data获取当前状态下的data，通过this.$options.data()获取该组件初始状态下的data。
      //然后只要使用Object.assign(this.$data, this.$options.data())就可以将当前状态的data重置为初始状态
      Object.assign(this.$data, this.$options.data.call(this)); //加这句话就可以清空
      this.getCourStatus().then(() =>
        this.getPreList(true).then(() => this.getDetail(obj))
      );
    },
    smallChange(val) {
      this.smallData.splice(0, 1, { key: val });
    },
    audioClick(e) {
      if (this.audioPreview) {
        this.audioClose(this.audioInfo)
      }
      setTimeout(() => {
        this.audioPlay(e)
      }, 300)
    },
    audioPlay(e) {
      this.audioInfo = e
      // 播放音乐
      this.videoClick(this.audioInfo);
      // 显示暂停按钮，隐藏播放按钮
      this.audioInfo.play = true;
      this.audioPreview = true
      this.timer = setInterval(() => {
        if (this.audioInfo.duration < this.audioInfo.time) {
          this.audioInfo.duration++
        } else {
          this.audioClose(this.audioInfo)
        }
      }, 1000);
    },
    audioClose(e) {
      this.videoClose()
      e.play = false;
      e.duration = 0;
      clearInterval(this.timer);
      this.audioPreview = false
    }
  },
  watch: {
    bigData() {
      if (this.bigData && this.bigData.length > 2) {
        let bigN = this.bigData.filter((e) => e.key == 0).length;
        this.bigStatus = ["通过", "半辅", "全辅", "全辅"][bigN];
        this.bigOperaShow = bigN !== 0 ? false : true;
      }
    },
  },
  computed: {
    trainScore() {
      if (
        this.$route.query.subject == 1 &&
        (this.detail.teach_type == 1 || this.detail.teach_type == 2)
      ) {
        let total = 0;
        if (this.trainData) {
          this.trainData.map((e) => (total += Number(e.score)));
        }
        return this.trainData && this.trainData.length > 0
          ? Math.round(total / this.trainData.length)
          : "";
      }
    },
    errorScore() {
      let total = 0;
      if (this.trainData && this.trainData[this.errorPertain]) {
        this.trainData[this.errorPertain].list.map(
          (e) => (total += Number(e.score))
        );
        return this.trainData[this.errorPertain].list.length > 0
          ? Math.round(total / this.trainData[this.errorPertain].list.length)
          : "";
      }
      return "";
    },
    stuSelect() {
      if (this.$route.query.subject == 2 && this.detail.is_divide == 0) {
        return this.detail.stu_list.find((e) => e.show == true);
      }
    },
    stuPassRate() {
      if (this.$route.query.subject == 2 && this.detail.is_divide == 0) {
        let arr = this.detail.train.filter((e) => e.is_adopt !== "");
        if (arr.length > 0) {
          return Math.round(
            (arr.filter((o) => o.is_adopt === 1).length / arr.length) * 100
          );
        } else {
          return "";
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
}
.class_page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #fbf7f7;
  min-height: 100vh;
  padding-top: 0.6rem;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2rem;
    height: 0.6rem;
    // background: rgba(255, 255, 255, 0.4);
    background: #fbedea;
    z-index: 4;
    .left {
      .van-icon {
        font-size: 0.24rem;
      }
    }
    .info {
      flex: auto;
      ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        li {
          font-size: 0.17rem;
          color: #333;
          &::before {
            content: "";
            display: inline-block;
            width: 0.06rem;
            height: 0.06rem;
            background: #ff5f4c;
            border-radius: 50%;
            margin-right: 0.1rem;
            vertical-align: middle;
          }
        }
      }
    }
    .btn {
      div {
        height: 0.34rem;
        line-height: 0.34rem;
        background: #f14947;
        border-radius: 6px;
        padding: 0 0.18rem;
        font-size: 0.14rem;
        color: #fff;
      }
    }
  }
  .body {
    padding-top: 0.13rem;
    display: flex;
    position: relative;
    .previewList {
      background: #fff;
      width: 1.5rem;
      height: calc(100vh - 0.73rem);
      padding: 0.12rem 0.14rem;
      border-radius: 0 0.1rem 0 0;
      margin-right: 0.17rem;
      ul {
        height: 100%;
        overflow-y: scroll;
        li {
          background: #f9f9f9;
          border-radius: 0.04rem;
          border: 0.01rem solid #e3e3e3;
          padding: 0.04rem 0;
          text-align: center;
          font-size: 0.14rem;
          color: #666;
          margin-bottom: 0.1rem;
          &:last-child {
            margin-bottom: 0;
          }
          &.cur {
            background: #ffeeee;
            border: 1px solid #ffadac;
            color: #f14947;
          }
        }
      }
    }
    .tags {
      position: absolute;
      left: 0;
      top: 1.7rem;
      z-index: 2;
      .btn {
        width: 0.32rem;
        height: 1rem;
        background: #ff5f4c;
        box-shadow: 0px 0px 0.3rem 0.01rem rgba(87, 1, 1, 0.28);
        border-radius: 0 0.15rem 0.15rem 0;
        font-size: 0.14rem;
        color: #fff;
        writing-mode: vertical-lr;
        letter-spacing: 0.05rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.12rem;
        &:nth-child(2) {
          background: #ffaa20;
          box-shadow: 0px 0px 0.3rem 0.01rem rgba(109, 44, 0, 0.28);
        }
      }
    }
    .main {
      position: relative;
      flex: 1;
      height: calc(100vh - 0.73rem);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      z-index: 1;
      &.pre {
        border-radius: 0.1rem 0 0;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: #8a8686;
          opacity: 0.58;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
        }
        .details .edict,
        .details .notes,
        .details .target,
        .details .modality {
          padding: 0.09rem 0.15rem 0.15rem;
        }
        .details .modality {
          display: block;
          ul {
            max-width: 100%;
          }
        }
      }
      .scroll {
        height: 100%;
        overflow-y: scroll;
      }
      .details {
        flex: 1;
        position: relative;
        padding-top: 0.4rem;
        .head {
          font-size: 0.16rem;
          color: #333;
          height: 0.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.01rem solid #f2f2f2;
          background: #fff;
          border-radius: 0 0.1rem 0 0;
          padding: 0 0.15rem;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          .title{
            flex: 1;
            margin: 0 0.1rem;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .tool {
            font-size: 0.14rem;
            color: #f14947;
          }
        }
        .basic_info {
          padding: 0.1rem 0.15rem 0.2rem;
          background: #fff;
          .result_info {
            line-height: 0.4rem;
            background: #fff3f3;
            border-radius: 0.06rem;
            font-size: 0.14rem;
            color: #460808;
            padding: 0 0.16rem;
            margin-bottom: 0.1rem;
            .tea {
              margin-left: 0.4rem;
            }
          }
          .list {
            .empty {
              font-size: 0.14rem;
              color: #999;
              text-align: center;
              margin: 0.35rem 0 0.2rem;
              /deep/ .van-empty {
                padding: 0;
                .van-empty__description {
                  font-size: 0.12rem;
                  color: #c2c2c2;
                  margin: 0.05rem 0 0;
                }
              }
            }
            .not_empty {
              .divide_data {
                .top {
                  display: flex;
                  justify-content: space-between;
                  font-size: 0.16rem;
                  color: #333;
                  span {
                    font-size: 0.13rem;
                    color: #f14947;
                  }
                }
                .content {
                  margin-top: 0.08rem;
                  li {
                    font-size: 0.14rem;
                    color: #666;
                    display: flex;
                    margin: 0.06rem 0;
                    p {
                      &.time {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      }
                      &.tea,
                      &.result {
                        margin-left: 3%;
                        white-space: nowrap;
                      }
                    }
                  }
                }
              }
              .train_data {
                .gexun,
                .zhineng {
                  .top {
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.16rem;
                    color: #333;
                    span {
                      font-size: 0.13rem;
                      color: #f14947;
                    }
                  }
                  .content {
                    margin-top: 0.08rem;
                    li {
                      font-size: 0.14rem;
                      color: #666;
                      display: flex;
                      .type {
                        width: 1rem;
                        line-height: 0.4rem;
                      }
                      .items {
                        display: flex;
                        flex: 1;
                        flex-wrap: wrap;
                      }
                      .d_item {
                        width: 0.4rem;
                        line-height: 0.24rem;
                        text-align: center;
                        background: #f3f3f3;
                        border-radius: 0.05rem;
                        border: 0.01rem solid #d9d9d9;
                        margin: 0.07rem 0.04rem;
                        &.err {
                          background: #ffe8e8;
                          border: 1px solid #ffbcbc;
                          color: #ff5f4c;
                          .pop {
                            display: flex;
                            padding: 0.12rem 0.06rem;
                            .pop_item {
                              width: 0.42rem;
                              height: 0.24rem;
                              background: #ffffff;
                              background: #f3f3f3;
                              border: 0.01rem solid #d9d9d9;
                              margin: 0 0.06rem;
                              border-radius: 0.12rem;
                            }
                          }
                        }
                      }
                      .is_now {
                        width: 0.22rem;
                        height: 0.22rem;
                        line-height: 0.22rem;
                        border-radius: 50%;
                        text-align: center;
                        background: #ffeae9;
                        border: 0.01rem solid #ffd9d9;
                        color: #f14947;
                        margin: 0.07rem 0 0 0.12rem;
                      }
                    }
                  }
                }
                .zhineng li {
                  margin: 0.06rem 0;
                  p {
                    &.tg {
                      max-width: 3.2rem;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                    &.tea,
                    &.sta {
                      margin-left: 8%;
                      white-space: nowrap;
                    }
                  }
                }
                .onetomany {
                  .top {
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.16rem;
                    color: #333;
                    p {
                      display: inline;
                      color: #460808;
                      margin-left: 0.4rem;
                      &:first-child {
                        margin-left: 0;
                      }
                    }
                    span {
                      font-size: 0.13rem;
                      color: #f14947;
                    }
                  }
                  .content {
                    margin-top: 0.1rem;
                    ul {
                      display: flex;
                      flex-wrap: wrap;
                      li {
                        margin: 0.05rem 0.13rem;
                        .name {
                          width: 0.95rem;
                          line-height: 0.3rem;
                          background: #f9f9f9;
                          border-radius: 0.04rem;
                          border: 0.01rem solid #e3e3e3;
                          font-size: 0.14rem;
                          text-align: center;
                          margin: 0 auto;
                          color: #333;
                          overflow: hidden;
                          &.off {
                            color: #d1d1d1;
                            position: relative;
                            &::after {
                              content: "假";
                              display: block;
                              width: 0.3rem;
                              height: 0.2rem;
                              background: #ff5f4c;
                              border-radius: 0.15rem;
                              position: absolute;
                              top: -0.03rem;
                              right: -0.13rem;
                              color: #fff;
                              font-size: 0.12rem;
                              line-height: 0.23rem;
                              text-align: left;
                              padding-left: 0.03rem;
                              box-sizing: border-box;
                            }
                          }
                        }
                        .is_adopt {
                          text-align: center;
                          margin-top: 0.05rem;
                          font-size: 0.16rem;
                          color: #333;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .edict,
        .notes,
        .target,
        .pass_rate {
          background: #fff;
          margin-top: 0.06rem;
          padding: 0.09rem 0.15rem 0.15rem 0.3rem;
          .title {
            font-size: 0.16rem;
            color: #333;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .more {
              font-size: 0.14rem;
              color: #f14947;
            }
          }
          .con {
            padding: 0.1rem;
            background: #fbf7f7;
            border-radius: 0.04rem;
            line-height: 0.24rem;
            font-size: 0.14rem;
            color: #666;
            margin-top: 0.08rem;
            min-height: 0.45rem;
            word-break: break-all;
          }
        }
        .note_many {
          .title {
            justify-content: flex-start;
            .name {
              color: #750100;
              margin-left: 0.05rem;
            }
          }
        }
        .pass_rate {
          .con {
            padding: 0;
            background: none;
            border-radius: 0;
            line-height: 0.5rem;
            font-size: 0.18rem;
            text-align: center;
            color: #f14947;
            &.not {
              font-size: 0.16rem;
              color: #c2c2c2;
            }
          }
        }
        .target {
          .con {
            font-size: 0.16rem;
            color: #750100;
            word-break: break-all;
          }
          .add {
            text-align: center;
            color: #ff5f4c;
            font-size: 0.16rem;
            span {
              margin-left: 0.05rem;
            }
          }
        }
        .big,
        .train_record > div {
          background: #fff;
          margin-top: 0.06rem;
          padding: 0.13rem 0.15rem 0.13rem 0.3rem;
          display: flex;
          p {
            font-size: 0.16rem;
            color: #333;
            line-height: 0.44rem;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            margin-right: 0.15rem;
            max-width: 82%;
            li {
              width: 0.4rem;
              height: 0.34rem;
              background: #ffffff;
              border-radius: 0.17rem;
              border: 0.01rem solid #dfdfdf;
              text-align: center;
              line-height: 0.34rem;
              margin: 0.05rem;
              font-size: 0.16rem;
              color: #666;
              position: relative;
              &::after {
                content: "";
                display: block;
                width: 0.04rem;
                height: 0.04rem;
                background: #ff5f4c;
                border-radius: 50%;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -0.1rem;
                margin: auto;
              }
              &.selected {
                background: #f5f5f5;
                &::after {
                  display: none;
                }
              }
              &.active {
                background: #ffeeee;
                border-color: #ffadac;
                color: #f14947;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .big.r {
          padding: 0.13rem 0.15rem;
          &.not {
            display: block;
            .con {
              text-align: center;
              font-size: 0.16rem;
              color: #c2c2c2;
              line-height: 2;
            }
          }
        }
        .train_record > div ul li {
          min-width: 0.4rem;
          width: fit-content;
          padding: 0 0.05rem;
        }
        .train_record > div .backout {
          font-size: 0.13rem;
          color: #f14947;
          line-height: 0.44rem;
        }
        .modality {
          background: #fff;
          margin-top: 0.06rem;
          padding: 0.18rem 0.15rem 0.18rem 0.3rem;
          display: flex;
          p {
            font-size: 0.16rem;
            color: #333;
            line-height: 0.44rem;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            max-width: 85%;
            li {
              width: 0.86rem;
              border-radius: 0.06rem;
              border: 0.01rem solid #dfdfdf;
              font-size: 0.14rem;
              color: #666;
              text-align: center;
              line-height: 0.32rem;
              margin: 0.07rem;
              &.active {
                background: #ffeeee;
                border-color: #ffadac;
                color: #f14947;
              }
            }
          }
        }
        .btns {
          margin: 0.4rem auto;
          display: flex;
          justify-content: center;
          .btn {
            line-height: 0.32rem;
            margin: 0 0.11rem;
            padding: 0 0.12rem;
            background: #ffffff;
            border-radius: 0.06rem;
            border: 0.01rem solid #b4b4b4;
            font-size: 0.16rem;
            color: #333;
            &:nth-child(2) {
              background: #f14947;
              border: none;
              color: #fff;
            }
          }
        }
      }
      .tool_box {
        flex: 1;
        background: #fff;
        border-radius: 0 0.1rem 0 0;
        padding: 0 0.15rem 0.2rem;
        position: relative;
        overflow: hidden;
        .title {
          line-height: 0.4rem;
          font-size: 0.17rem;
          color: #333;
          height: 0.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.01rem solid #f2f2f2;
          .back {
            font-size: 0.14rem;
          }
        }
        .con {
          padding-top: 0.14rem;
          .tabs {
            width: fit-content;
            padding: 0.05rem 0.12rem;
            margin: 0 auto;
            background: #f9f9f9;
            border-radius: 0.06rem;
            span {
              display: inline-block;
              width: 1rem;
              height: 0.36rem;
              background: #fff;
              border-radius: 0.06rem;
              font-size: 0.16rem;
              color: #333;
              text-align: center;
              line-height: 0.36rem;
              margin-right: 0.12rem;
              &:last-child {
                margin-right: 0;
              }
              &.cur {
                background: #ff5f4c;
                color: #fff;
              }
            }
          }
          .list {
            height: calc(100vh - 1.83rem);
            margin-top: 0.1rem;
          }
          .img_box .item {
            .name {
              font-size: 0.16rem;
              color: #333;
            }
            .item_imgs {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .image {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 0.08rem;
                overflow: hidden;
                margin: 0.08rem 0;
                text-align: center;
                img {
                  width: auto;
                  height: 100%;
                }
              }
              .zw {
                width: 1.8rem;
                height: 0;
              }
            }
          }
          .video_box {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .item {
              width: 2.44rem;
              position: relative;
              border-radius: 0.08rem;
              overflow: hidden;
              margin: 0.08rem 0;
              display: flex;
              flex-direction: column;
              height: fit-content;
              .name {
                background: #f4f4f4;
                padding: 0.08rem 0.15rem;
                font-size: 0.15rem;
                color: #333;
                flex: 1;
              }
              .mask {
                width: 100%;
                height: 1.32rem;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(255, 255, 255, 0.4);
                z-index: 13;
                text-align: center;
                line-height: 1.32rem;
                .van-icon {
                  color: #fff;
                  font-size: 0.34rem;
                }
              }
            }
            .zw {
              width: 2.44rem;
              height: 0;
            }
            /deep/ .prism-player .prism-big-play-btn {
              display: none !important;
              .outter {
                display: none;
              }
            }
          }
          .preview_box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 999;
            &.audio {
              z-index: -1;
            }
            .image {
              width: 5rem;
              height: 5rem;
              padding: 0.24rem;
              background: #fff;
              border-radius: 0.08rem;
              overflow: hidden;
              position: relative;
              img {
                height: 100%;
                border-radius: 0.08rem;
                object-fit: contain;
              }
            }
            .videos {
              width: 6.31rem;
              height: 3.63rem;
              padding: 0.2rem 0.28rem;
              background: #fff;
              border-radius: 0.08rem;
              overflow: hidden;
              position: relative;
              /deep/ .prism-player .prism-big-play-btn {
                top: 0;
                bottom: 0 !important;
                left: 0 !important;
                right: 0;
                margin: auto;
              }
            }
            .close {
              width: 0.6rem;
              height: 0.6rem;
              background: rgba(0, 0, 0, 0.5);
              position: absolute;
              top: 0;
              right: 0;
              text-align: center;
              line-height: 0.6rem;
              color: #fff;
              font-size: 0.24rem;
              z-index: 15;
            }
          }
        }
      }
      .record {
        width: 2.1rem;
        background: #fff;
        margin-left: 0.16rem;
        border-radius: 0.1rem 0 0;
        padding: 0.4rem 0.15rem 0.2rem;
        position: relative;
        .title {
          font-size: 0.16rem;
          color: #333;
          border-bottom: 0.01rem solid #f2f2f2;
          height: 0.4rem;
          line-height: 0.4rem;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: calc(100% - 0.3rem);
          margin: auto;
        }
        .operation {
          .btn_box {
            text-align: center;
            display: flex;
            justify-content: center;
            &.flex_col {
              flex-direction: column;
              align-items: center;
            }
          }
          .btn {
            display: inline-block;
            padding: 0 0.17rem;
            line-height: 0.32rem;
            background: #ffffff;
            border-radius: 0.23rem;
            width: fit-content;
            border: 0.01rem solid #999999;
            margin: 0.1rem 0.07rem;
            font-size: 0.18rem;
            color: #333;
            box-sizing: border-box;
            &.fixed_width {
              width: 0.62rem;
              padding: 0;
            }
            &.fwidth_100 {
              width: 1.1rem;
              font-size: 0.16rem;
            }
            &.active {
              background: #ffeeee;
              border-color: #ffadac;
              color: #f14947;
              span {
                color: #f14947 !important;
              }
            }
          }
          .big_operation {
            margin-top: 0.3rem;
          }
          .train_operation {
            .btn span {
              font-size: 0.14rem;
              color: #999;
            }
          }
          .role_game {
            .f {
              background: #f5f5f5;
              border-radius: 0.1rem;
              padding: 0.05rem;
              margin-top: 0.1rem;
            }
            .status_btn {
              .btn {
                width: 0.72rem;
                font-size: 0.14rem;
                padding: 0;
                border-color: #b4b4b4;
                border-radius: 0.06rem;
                &.active {
                  background: #f14947;
                  border: none;
                  color: #fff;
                  border-color: #f14947;
                }
              }
            }
          }
          .operation_title {
            font-size: 0.16rem;
            color: #333;
            margin: 0.25rem 0;
          }
          .confirm_btn {
            width: 0.66rem;
            height: 0.34rem;
            background: #f14947;
            border-radius: 0.06rem;
            text-align: center;
            line-height: 0.34rem;
            color: #fff;
            margin: 0.3rem auto 0;
            font-size: 0.14rem;
          }
          .many_operation {
            height: calc(100vh - 1.24rem);
            margin-top: 0.1rem;
            padding-bottom: 0.1rem;
            li {
              margin-top: 0.12rem;
              .name {
                width: 1rem;
                line-height: 0.3rem;
                background: #f9f9f9;
                border-radius: 0.04rem;
                border: 0.01rem solid #e3e3e3;
                font-size: 0.14rem;
                text-align: center;
                margin: 0 auto;
                color: #666;
                overflow: hidden;
                &.active {
                  background: #ffeeee;
                  border: 1px solid #ffadac;
                  color: #f14947;
                }
                &.off {
                  color: #d1d1d1;
                  position: relative;
                  &::after {
                    content: "假";
                    display: block;
                    width: 0.3rem;
                    height: 0.2rem;
                    background: #ff5f4c;
                    border-radius: 0.15rem;
                    position: absolute;
                    top: -0.03rem;
                    right: -0.13rem;
                    color: #fff;
                    font-size: 0.12rem;
                    line-height: 0.23rem;
                    text-align: left;
                    padding-left: 0.03rem;
                    box-sizing: border-box;
                  }
                }
              }
              .stu_btns {
                .btn {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
/deep/ .van-dialog {
  padding-bottom: 0.2rem;
  font-size: 0.16rem;
  .van-dialog__header {
    text-align: left;
    padding: 0.2rem 0.24rem 0;
    color: #666;
  }
  .van-dialog__header::before {
    content: "!";
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
    border-radius: 50%;
    text-align: center;
    font-size: 0.18rem;
    vertical-align: text-bottom;
    margin-right: 0.08rem;
    line-height: 0.2rem;
    padding: 0 0 0.01rem 0.01rem;
    background: #ffaa20;
    color: #fff;
    vertical-align: middle;
  }
  .van-dialog__message--has-title {
    padding-top: 0.1rem;
    padding-bottom: 0.15rem;
    font-size: 0.14rem;
    line-height: 0.2rem;
  }
  .van-dialog__footer {
    justify-content: center;
    overflow: initial;
    .van-button {
      width: 0.7rem;
      height: 0.32rem;
      flex: none;
      border-radius: 0.06rem;
      margin: 0 0.13rem;
      font-size: 0.14rem;
      box-sizing: border-box;
    }
    .van-dialog__cancel {
      border: 1px solid #b2b2b2;
      color: #707070;
    }
    .van-dialog__confirm {
      background: #fa4b52;
      color: #fff;
    }
  }
  [class*="van-hairline"]::after {
    border: none;
  }
}
@media screen and (min-width: 640px) and (max-width: 820px) {
  .class_page .header .info ul li {
    font-size: 0.14rem;
  }
  .class_page .body .main {
    .tool_box .con .img_box .item .item_imgs {
      .image {
        width: 1.6rem;
        height: 1.6rem;
      }
      .zw {
        width: 1.6rem;
      }
    }
    .details .big ul,
    .details .train_record ul {
      max-width: 70%;
    }
    .details .basic_info .list .not_empty .train_data .zhineng li p.tg {
      max-width: 2.4rem;
    }
  }
}
/* Player容器 */
.audio-player {
  position: relative;
  width: 100%;
  height: 0.5rem;
  background: #f4f4f4;
  & + .name {
    border-top: 1px solid #ececec;
    flex: inherit !important;
  }
}

.control-panel {
  display: flex;
  align-items: center;
}

/* 播放按钮 */
.play-btn {
  display: block;
  // position: absolute;
  // left: 0;
  // top: 50%;
  // transform: translateY(-50%);
  width: 0.6rem;
  height: 0.6rem;
  background-image: url("~@/assets/img/play.png");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 1;
}

/* 暂停按钮 */
.pause-btn {
  display: block;
  // position: absolute;
  // left: 0;
  // top: 50%;
  // transform: translateY(-50%);
  width: 0.6rem;
  height: 0.6rem;
  background-image: url("~@/assets/img/play.png");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 1;
}
.duration {
  margin-left: -0.05rem;
}
.duration,
.total {
  font-size: 0.15rem;
  &.red {
    color: #f14947;
  }
}

/* 进度条 */
.progress-bar {
  display: block;
  // position: absolute;
  // left: .6rem;
  // top: 50%;
  // transform: translateY(-50%);
  width: 0.7rem;
  height: 0.14rem;
  border-radius: 0.03rem;
  background: url("~@/assets/img/audio_bar.png") left top/auto 100% space;
  overflow: hidden;
  z-index: 0;
  margin: 0 0.05rem;
}

/* 进度条滑块 */
.progress-bar::-webkit-slider-thumb {
  height: 0.14rem;
  width: 0.14rem;
  border-radius: 50%;
  background-color: #fff;
  -webkit-appearance: none;
}
</style>
