<template>
  <div class="body">
    <van-nav-bar left-text="返回" left-arrow @click-left="onpage">
      <template #right>
        <van-button v-if="number == 1" class="btn" type="warning" @click="complete('finish')">完成评估</van-button>
        <div class="shownumber" v-if="number > 1">
          <div class="div1">正确{{ result.num_true }}个</div>
          <div class="div2">错误{{ result.num_false }}个</div>
        </div>
      </template>
    </van-nav-bar>
    <div class="from">
      <div class="mycard">
        <van-button class="pingubtn" type="danger">{{ tagname + " - " + mileage_name }}</van-button>
        <div class="text">{{ coppydata.title }}</div>
        <div class="view-btns">
          <div class="btn" :class="{active: viewStatus === 1}" @click="viewStatus = 1">查看教具<van-icon name="arrow-down" /></div>
          <div class="btn" :class="{active: viewStatus === 2}" @click="viewStatus = 2">查看评分标准<van-icon name="arrow-down" /></div>
        </div>
        <div class="view-content" v-if="viewStatus">
          <template v-if="viewStatus === 1">
            <div style="line-height: 1.5;">{{ tool || '无' }}</div>
          </template>
          <template v-if="viewStatus === 2">
            <div class="standard">
              <span class="score">0.5分</span>
              <span>{{ half_norm }}</span>
            </div>
            <div class="standard">
              <span class="score">1分</span>
              <span>{{ full_norm }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="text child-title" v-if="!btnshow">{{ "(" + ['','单选','多选'][coppydata.type] + ")" + child_title }}</div>
      <div class="starting" v-if="btnshow" @click="addBtnFlag && twodata(i)">开始答题</div>
      <div class="starting" v-if="thisshow">本练习题结束</div>
      <div class="imageproblem" v-if="type === 'picture'">
        <div class="picturelist" v-if="imglist">
          <ul>
            <li v-for="(item,index) in imgsarr" :key="item.id" @click="preview(index)">
              <img :src="item.url" alt />
            </li>
          </ul>
        </div>
        <div class="sample" v-if="src && !btnshow" @click="preview(0,true)">
          <img :src="src" alt />
          <p>样本图片</p>
        </div>
      </div>
      <div class="videoproblem" v-if="type === 'video'">
        <div class="toovideo" v-if="!btnshow">
          <aliplayer v-if="vId.length > 0" :vId="vId" :length="videosarr.length"></aliplayer>
        </div>
      </div>
      <div class="btngroup" v-if="btngroup">
        <div class="xxBox">
          <button v-for="(optionItem, i) in optionArr" :key="i" :class="{trues: userAnswer.some(e=>e.key * 1 === optionItem.key * 1)}" @click="choose(optionItem, $event)">{{ optionItem.content }}</button>
        </div>
      </div>
    </div>
    <div class="scorebtn" @click="scoreshow">
      <div class="box"></div>
    </div>
    <div class="picbtn" v-if="picbtn">
      <div class="buttongroup">
        <div class="picpre pb" style="border: 1px solid #ff8a88" @click="picpre" v-show="picpreshow">上一题</div>
        <div class="picnext pb" style="border: 1px solid #ff8a88" @click="picnext" v-show="picnextshow">下一题</div>
      </div>
    </div>
    <div class="fixedcard">
      <div class="buttongroup preTopic" v-if="this.coppydata.topic_sort > 1" @click="ona">
        <div style="border: 1px solid #ff8a88">上一题</div>
      </div>
      <div class="score">
        <span :class="{ active: scorerecord.fullscore === 0, active1: zero }" @click="scoreclick(0)">0分</span>
        <span :class="{ active: scorerecord.fullscore == 0.5, active1: zerofive }" @click="scoreclick(0.5)">0.5分</span>
        <span :class="{ active: scorerecord.fullscore == 1, active1: one }" @click="scoreclick(1)">1分</span>
      </div>
      <div class="buttongroup">
        <div v-if="text === '手动'" style="background: #eea837; color: #fff" @click="manual('手动')">手动打分</div>
        <div v-if="text === '自动'" style="background: #eea837; color: #fff" @click="manual('自动')">恢复自动</div>
        <div style="border: 1px solid #ff8a88" @click="next">下一题</div>
      </div>
    </div>
    <!-- 分数弹框 -->
    <van-popup class="scorebox" v-model="show">
      <div class="calculatescore" v-for="(item, index) in scorearr" :key="index">
        <p :class="{ disabled: item.toprecord_id == 0 && +item.score == 1 }" @click="backSee(item.topic_sort, item.toprecord_id)">{{ item.name }}</p>
        <ul>
          <li :class="{ active: item.score === 0 || item.score === '0.0' }">0分</li>
          <li :class="{ active: item.score == 0.5 }">0.5分</li>
          <li :class="{ active: item.score == 1 }">1分</li>
        </ul>
      </div>
    </van-popup>
    <van-overlay :show="overlayShow" :custom-style="{ background: 'rgba(0,0,0,0)', zIndex: '99' }" @click="overlayTip" :lock-scroll="false" />
    <van-image-preview v-model="imgPreview" :images="images" closeable show-indicators :start-position="previewIndex"></van-image-preview>
  </div>
</template>

<script>
import { Notify, Dialog, Toast, Overlay } from "vant";
import "../../../plugin/element";
import { getuid, getreviewid, gettopicid, getlesson_id, getltemname, settopicid, setlesson_id, setltemname } from "/src/api/userdata.js";
import { getexercises, rightscore, recordadd, topicRecord, finishAsses, nextTopic, nextContent } from "../../../api/index";
import aliplayer from "@/components/aliplay";
export default {
  data() {
    return {
      show: false,
      nextarr: [],
      scorearr: [],
      numindex: "",
      text: "手动",
      tableshow: false,
      istrue: false,
      isfalse: false,
      src: "",
      type: "",
      videosrc: "https://www.runoob.com/try/demo_source/movie.mp4",
      // 练习题的数据
      btnshow: false,
      coppydata: [],
      tagname: "",
      mileage_name: "",
      idarr: {
        uid: "",
        reviewid: "",
        mainid: "",
      },
      number: "",
      i: 0,
      mycard: false,
      completeshow: true,
      imgsarr: [],
      videosarr: [],
      numtrue: 0,
      numfalse: 0,
      num: 0,
      // 分数按钮
      zero: false,
      zerofive: false,
      one: false,
      auto: "",
      scorerecord: {
        fullscore: "",
        fullcoppy: "",
        halfscore: "",
        halfcoppy: "",
      },
      btngroup: false,
      isok: false,
      numtrues: 0,
      numfalses: 0,
      numberof: 0,
      imglist: false,
      picbtn: false,
      picpreshow: true,
      picnextshow: true,
      picindex: 0,
      recordArr: [],
      addBtnFlag: true,
      picid: 0,
      nextmainid: "",
      nextname: "",
      nextlessonid: "",
      topic_sort: 0,
      questionid: "",
      trecord_id: "", //编辑必传—评估记录主表id
      toprecord_id: "", //编辑必传—答题记录主表id
      setarr: [],
      overlayShow: false,
      isLive: false,
      visitable: false,
      videoUrl: "",
      vId: [],
      thisshow: false,
      optionArr: [],
      answers: [],
      userAnswer: [],
      yesNum: 0,
      isend: true,
      imgPreview: false,
      images: [],
      previewIndex: 0,
      answerList: [],
      child_title: '',
      viewStatus: 0,
      tool: '',
      full_norm: '',
      half_norm: '',
      // 范例题栈
      exampStack: [],
      // 范例题分组结果
      exampGroupResult: [],
    };
  },
  computed: {
    result() {
      let num_true = 0
      let num_false = 0
      if (this.coppydata.is_examp) {
        this.exampGroupResult.map(e => {
          let flag = e.resultList.some(o => !o.result)
          if (flag) {
            num_false += 1
          } else {
            let isAll = e.resultList.length === e.list.length
            if (isAll) {
              num_true += 1
            }
          }
        })
      } else {
        this.recordArr.map(e => {
          if (e.answer.length > 0) {
            let obj = this.answerList.find(o => o.id * 1 === e.id * 1)
            if (obj && obj.type * 1 === 1) {
              if (e.answer[0].key * 1 === obj.answer[0].key * 1) {
                num_true += 1
              } else {
                num_false += 1
              }
            }
            if (obj && obj.type * 1 === 2) {
              let flag = e.answer.length === obj.answer.length && e.answer.every(o => obj.answer.some(i => i.key * 1 === o.key * 1))
              if (flag) {
                num_true += 1
              } else {
                num_false += 1
              }
            }
          }
        })
      }
      return {
        num_true,
        num_false,
      }
    },
  },
  watch: {
    recordArr: {
      handler(newVal, oldVal) {
        if (this.coppydata.is_examp && newVal.length > 0) {
          this.exampGroupResult.map(e => {
            let arr = []
            e.list.map(i => {
              let obj = newVal.find(o => o.id * 1 === i * 1)
              if (obj && obj.answer.length > 0) {
                let item = this.answerList.find(o => o.id * 1 === obj.id * 1)
                let flag = false
                if (item && item.type * 1 === 1) {
                  flag = obj.answer[0].key * 1 === item.answer[0].key * 1
                }
                if (item && item.type * 1 === 2) {
                  flag = obj.answer.length === item.answer.length && obj.answer.every(o => item.answer.some(i => i.key * 1 === o.key * 1))
                }
                arr.push({ id: obj.id, result: flag })
              }
            })
            this.$set(e, 'resultList', arr)
          })
        }
      },
      deep: true
    },
  },
  created() {
    this.getlist();
  },
  components: { "van-overlay": Overlay, aliplayer },
  methods: {
    getlist() {
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.tagname = getltemname();
      if (this.$route.query.topic_sort) {
        let preTopicSort = this.$route.query.topic_sort;
        let not = this.$route.query.not;
        let type = this.$route.query.type;
        topicRecord(uid, preTopicSort, reviewid, mainid, type).then((res) => {
          if (res.code === 9990) {
            Notify({ type: "danger", message: res.msg });
            this.$router.replace({ name: "login" });
          }
          if (res.code != 1) {
            return Notify({ type: "danger", message: res.msg });
          }
          this.coppydata = res.data.topic_info;
          this.coppydata.is_examp = res.data.topic_info.is_examp * 1;
          this.mileage_name = this.coppydata.mileage_name;
          this.num = this.coppydata.main_id;
          this.topic_sort = this.coppydata.topic_sort;
          this.questionid = this.coppydata.id;
          this.trecord_id = res.data.trecord_id;
          this.toprecord_id = res.data.id;
          this.scorerecord.fullscore = res.data.score;
          this.tool = this.coppydata.tool;
          this.full_norm = this.coppydata.full_norm;
          this.half_norm = this.coppydata.half_norm;
          // 如果是范例题，则初始化分组结果
          if (this.coppydata.is_examp) {
            this.exampGroupResult = this.coppydata.group.map(e => { return { ...e, resultList: [] } });
          }
          let answerArr = res.data.son_toprecrd;
          res.data.son_topic.map(e => {
            if (e.ans_option.length > 0) {
              this.recordArr.push({ id: e.id, type: e.type, answer: e.ans_option })
            }
            this.answerList.push({ id: e.id, type: e.type, answer: e.answer })
          });
          if (not == 1) {
            this.overlayShow = true;
          }
          for (let i = 0; i < this.coppydata.pract_ids.length; i++) {
            const element = this.coppydata.pract_ids[i].id;
            const answerId = answerArr[i].id;
            const answer = answerArr[i].answer;
            const ans_option = answerArr[i].ans_option;
            this.setarr[i] = {
              id: answerId,
              topic_id: element,
              answer: answer,
              ans_option: ans_option.length > 0 ? JSON.parse(ans_option) : [],
            };
          }
          this.i = this.coppydata.pract_ids[0].id;
          if (this.coppydata.pract_ids.length <= 1) {
            this.twodata(this.i);
          }
          this.getdata();
          this.getNextMain();
        });
      } else {
        let params = {
          uid: uid,
          review_id: reviewid,
          main_id: mainid,
          is_rec: this.$route.query.is_rec,
          trec_id: this.$route.query.trec_id,
          topic_id: this.$route.query.topic_id,
        };
        nextContent(params).then((res) => {
          if (res.code === 9990) {
            Notify({ type: "danger", message: res.msg });
            this.$router.replace({ name: "login" });
          }
          this.coppydata = res.data;
          this.coppydata.is_examp = res.data.is_examp * 1;
          this.mileage_name = this.coppydata.mileage_name;
          this.num = this.coppydata.main_id;
          this.topic_sort = this.coppydata.topic_sort;
          this.questionid = this.coppydata.id;
          this.trecord_id = this.coppydata.trecord_id;
          this.tool = this.coppydata.tool;
          this.full_norm = this.coppydata.full_norm;
          this.half_norm = this.coppydata.half_norm;
          // 如果是范例题，则初始化分组结果
          if (this.coppydata.is_examp) {
            this.exampGroupResult = this.coppydata.group.map(e => { return { ...e, resultList: [] } });
          }
          for (let i = 0; i < this.coppydata.pract_ids.length; i++) {
            const element = this.coppydata.pract_ids[i].id;
            this.setarr[i] = { id: "", topic_id: element, answer: 0, ans_option: "" };
          }
          this.i = this.coppydata.pract_ids[0].id;
          if (this.coppydata.pract_ids.length <= 1) {
            this.twodata(this.i);
          }
          this.getdata();
          this.getNextMain();
        });
      }
    },
    async getNextMain() {
      let thisMainIndex = "";
      const uid = getuid();
      const id = getreviewid();
      let pathArr = [
        "must-topic/main-list",
        "must-topic/skill-list",
        "must-topic/feel-list",
        "must-topic/rim-list",
      ];
      let params = {
        uid,
        review_id: id,
      };
      const res = await this.$http.get(pathArr[this.$route.query.eva_type], { params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.nextarr = res.data.list;
      for (let i = 0; i < this.nextarr.length; i++) {
        const element = this.nextarr[i].main_id;
        if (this.num == this.nextarr[this.nextarr.length - 1].main_id) {
          this.nextname = "最后一个维度了";
        }
        if (this.num == element) {
          thisMainIndex = i;
        } else if (this.num != element && this.nextarr[i].status != 1) {
          this.isend = false;
        }
        if (thisMainIndex == 0 && i > thisMainIndex && this.nextarr[i].status != 1) {
          this.nextmainid = this.nextarr[i].main_id;
          this.nextname = this.nextarr[i].main_name;
          this.nextlessonid = this.nextarr[i].lesson_id;
          return false;
        }
        if (thisMainIndex && i > thisMainIndex && this.nextarr[i].status != 1) {
          this.nextmainid = this.nextarr[i].main_id;
          this.nextname = this.nextarr[i].main_name;
          this.nextlessonid = this.nextarr[i].lesson_id;
          return false;
        } else if (thisMainIndex !== '' && i > thisMainIndex && this.nextarr[i].status == 1) {
          this.nextname = "最后一个维度了";
        }
      }
    },
    getdata() {
      this.idarr.uid = getuid();
      this.idarr.reviewid = getreviewid();
      this.idarr.mainid = gettopicid();
      if (this.coppydata.pract_ids.length > 1) {
        this.btnshow = true;
        this.btngroup = false;
        this.imglist = false;
      } else {
        this.mycard = true;
        this.btnshow = false;
        this.btngroup = true;
      }
      this.number = 1;
      this.tagname = getltemname();
    },
    twodata(index) {
      if (this.btnshow) {
        this.number = this.coppydata.pract_ids.length;
        this.picbtn = true;
        this.picpreshow = false;
      }
      this.btnshow = false;
      this.btngroup = true;
      this.imglist = true;
      getexercises(index).then((res) => {
        if (res.code === 1) {
          this.child_title = res.data.title;
          this.picid = res.data.id;
          this.src = res.data.sample;
          let content = res.data.top_content;
          let option = res.data.option;
          let answer = res.data.answer;
          this.answers = JSON.parse(answer);
          this.optionArr = JSON.parse(option);
          this.coppydata.type = res.data.type;
          let flag = this.answerList.some(item => item.id * 1 === this.picid * 1);
          if (!flag) {
            this.answerList.push({ id: this.picid, type: this.coppydata.type, answer: this.answers });
          }
          let obj = this.recordArr.find((e) => e.id == this.picid)
          if (obj) {
            this.userAnswer = JSON.parse(JSON.stringify(obj.answer))
          }
          this.imgsarr = [];
          if (content.img.length > 0) {
            this.type = "picture";
            const imgbox = content.img;
            this.imgsarr = imgbox;
          } else if (content.video.length > 0) {
            this.type = "video";
            const videobox = content.video;
            this.videosarr = videobox;
            for (let i in this.videosarr) {
              this.vId.push(this.videosarr[i].video_id);
            }
          }
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
    scoreclick(val) {
      if (this.auto == "手动") {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        if (val === 0) {
          this.zero = true;
          this.zerofive = false;
          this.one = false;
          this.scorerecord.fullscore = 0;
        } else if (val === 0.5) {
          this.zerofive = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 0.5;
        } else if (val === 1) {
          this.one = true;
          this.zero = false;
          this.one = false;
          this.scorerecord.fullscore = 1;
        }
      }
    },
    findTrueFalse() {
      for (let index = 0; index < this.recordArr.length; index++) {
        const value = this.recordArr[index];
        if (value == "正确") {
          this.numtrues++;
        }
        if (value == "错误") {
          this.numfalses++;
        }
      }
    },
    picpre() {
      // 点击练习题中的上一题
      this.numtrues = 0;
      this.numfalses = 0;
      this.istrue = false;
      this.isfalse = false;
      this.optionArr = [];
      this.vId = [];
      this.type = ''
      let romoveList = document.getElementsByClassName("xxBox")[0].children;
      for (let i = 0; i < romoveList.length; i++) {
        const element = romoveList[i];
        let romoveClass = element.getAttribute("class");
        if (romoveClass) {
          element.classList.remove("trues");
        }
      }
      this.yesNum = 0;
      if (this.coppydata.is_examp) {
        this.picindex = this.exampStack.pop();
      } else {
        this.picindex -= 1;
      }
      let nextid = this.coppydata.pract_ids[this.picindex].id;
      this.userAnswer = JSON.parse(JSON.stringify(this.recordArr)).find(e => e.id * 1 === nextid * 1).answer;
      this.findTrueFalse();
      this.twodata(nextid);
      this.picnextshow = true;
      if (this.picindex <= 0) {
        this.picpreshow = false;
      }
    },
    picnext() {
      // 点击练习题中的下一题
      const fullmark = Number(this.coppydata.full_mark);
      const halfmark = Number(this.coppydata.half_mark);
      let piclength = this.coppydata.pract_ids.length;
      this.numtrues = 0;
      this.numfalses = 0;
      this.optionArr = [];
      let obj = this.recordArr.find((e) => e.id == this.picid);
      if (obj) {
        obj.answer = this.userAnswer
      } else {
        this.recordArr.push({ id: this.picid, type: this.coppydata.type, answer: this.userAnswer })
      }
      for (let i = 0; i < this.setarr.length; i++) {
        const topic_id = this.setarr[i].topic_id;
        if (this.picid == topic_id) {
          this.setarr[i].ans_option = this.userAnswer;
          let ansObj = this.answerList.find(e => e.id * 1 === topic_id * 1);
          if (this.userAnswer.length > 0 && this.coppydata.type * 1 === 1) {
            let flag = ansObj.answer[0].key * 1 === this.userAnswer[0].key * 1
            this.setarr[i].answer = flag ? 1 : 0
          }
          if (this.userAnswer.length > 0 && this.coppydata.type * 1 === 2) {
            let flag = ansObj.answer.length === this.userAnswer.length && this.userAnswer.every(o => ansObj.answer.some(i => i.key * 1 === o.key * 1))
            this.setarr[i].answer = flag ? 1 : 0
          }
        }
      }

      this.findTrueFalse();
      this.istrue = false;
      this.isfalse = false;
      this.userAnswer = [];
      this.type = ''
      let romoveList = document.getElementsByClassName("xxBox")[0].children;
      if (this.picindex < piclength - 1) {
        this.vId = [];
      }
      this.yesNum = 0;
      this.$nextTick(() => {
        if (this.coppydata.is_examp) {
          if (!this.exampStack.includes(this.picindex)) {
            this.exampStack.push(this.picindex);
          }
          let arr = this.coppydata.pract_ids.filter((e, i) => i > this.picindex);
          this.exampGroupResult.map(e => {
            let flag = e.resultList.some(o => !o.result)
            if (flag) {
              arr = arr.filter(o => !e.list.some(i => i * 1 === o.id * 1) || e.resultList.some(i => i.id * 1 === o.id * 1))
            }
          })
          this.picindex = arr.length > 0 ? this.coppydata.pract_ids.findIndex(e => e.id * 1 === arr[0].id * 1) : piclength;
        } else {
          this.picindex += 1;
        }
        this.picpreshow = true;
        if (this.picindex == piclength - 1) {
          Dialog.alert({
            title: "提示",
            message: "即将开始本练习题的最后一题",
            confirmButtonColor: "#fff",
            messageAlign: "left",
            getContainer: ".body",
          }).then(() => {
            let nextid = this.coppydata.pract_ids[this.picindex].id;
            this.twodata(nextid);
          });
        }
        if (this.result.num_true >= fullmark) {
          this.scorerecord.fullscore = 1;
          this.scorerecord.fullcoppy = 1;
          this.isok = true;
        } else if (this.result.num_true >= halfmark) {
          this.scorerecord.fullscore = 0.5;
          this.scorerecord.fullcoppy = 0.5;
          this.isok = true;
        } else if (this.result.num_true < halfmark) {
          this.scorerecord.fullscore = 0;
          this.scorerecord.fullcoppy = 0;
          this.isok = true;
        }
        if (this.picindex >= piclength) {
          this.picbtn = false;
          this.addBtnFlag = false;
          this.number = 1;
        } else {
          for (let i = 0; i < romoveList.length; i++) {
            const element = romoveList[i];
            let romoveClass = element.getAttribute("class");
            if (romoveClass) {
              element.classList.remove("trues");
            }
          }
          let nextid = this.coppydata.pract_ids[this.picindex].id;
          this.twodata(nextid);
        }
      });
    },

    choose(val, e) {
      if (this.coppydata.pract_ids.length > 1 && this.number == 1) {
        return false;
      }
      const fullmark = Number(this.coppydata.full_mark);
      const halfmark = Number(this.coppydata.half_mark);
      let classList = e.currentTarget.getAttribute("class");
      let n = 0;
      if (this.coppydata.type * 1 === 1) {
        this.userAnswer = []
        this.yesNum = 0
      }
      if (classList) {
        for (let x = 0; x < this.userAnswer.length; x++) {
          const element = this.userAnswer[x];
          if (element.key == val.key) {
            this.userAnswer.splice(x, 1);
          }
        }
        for (let z = 0; z < this.answers.length; z++) {
          const an = this.answers[z];
          if (val.key === an.key) {
            this.yesNum--;
          }
        }
        if (this.coppydata.pract_ids.length == 1) {
          this.numtrues = this.yesNum;
        }
      } else {
        this.userAnswer.push({
          key: val.key,
          option: val.option,
        });
        for (let z = 0; z < this.answers.length; z++) {
          const an = this.answers[z];
          if (val.key === an.key) {
            this.yesNum++;
          }
        }
        if (this.coppydata.pract_ids.length == 1) {
          if (this.yesNum === this.userAnswer.length) {
            this.istrue = true;
            this.isfalse = false;
            n = 1;
            this.numtrues += 1;
            if (this.numfalses > 0) {
              this.numfalses -= 1;
            }
          } else {
            this.isfalse = true;
            this.istrue = false;
            n = 0;
            this.numfalses += 1;
            if (this.numtrues > 0) {
              this.numtrues -= 1;
            }
          }
        }
      }
      if (this.number == 1) {
        let obj = this.recordArr.find((e) => e.id == this.picid);
        if (obj) {
          obj.answer = this.userAnswer
        } else {
          this.recordArr.push({ id: this.picid, type: this.coppydata.type, answer: this.userAnswer })
        }
        for (let i = 0; i < this.setarr.length; i++) {
          const topic_id = this.setarr[i].topic_id;
          if (this.picid == topic_id) {
            this.setarr[i].ans_option = this.userAnswer;
            let ansObj = this.answerList.find(e => e.id * 1 === topic_id * 1);
            if (this.userAnswer.length > 0 && this.coppydata.type * 1 === 1) {
              let flag = ansObj.answer[0].key * 1 === this.userAnswer[0].key * 1
              this.setarr[i].answer = flag ? 1 : 0
            }
            if (this.userAnswer.length > 0 && this.coppydata.type * 1 === 2) {
              let flag = ansObj.answer.length === this.userAnswer.length && this.userAnswer.every(o => ansObj.answer.some(i => i.key * 1 === o.key * 1))
              this.setarr[i].answer = flag ? 1 : 0
            }
          }
        }
        if (this.result.num_true >= fullmark) {
          this.scorerecord.fullscore = 1;
          this.scorerecord.fullcoppy = 1;
          this.isok = true;
        } else if (this.result.num_true >= halfmark) {
          this.scorerecord.fullscore = 0.5;
          this.scorerecord.fullcoppy = 0.5;
          this.isok = true;
        } else if (this.result.num_true < halfmark) {
          this.scorerecord.fullscore = 0;
          this.scorerecord.fullcoppy = 0;
          this.isok = true;
        }
      }
      if (this.yesNum === this.answers.length && this.userAnswer.length == this.answers.length) {
        this.istrue = true;
      } else {
        this.isfalse = true;
      }
    },
    next() {
      this.istrue = false;
      this.isfalse = false;
      this.complete("add");
    },
    ona() {
      this.istrue = false;
      this.isfalse = false;
      this.complete("jian");
    },
    complete(val) {
      let uid = getuid();
      let reviewid = getreviewid();
      let mainid = gettopicid();
      let lesson_id = getlesson_id();
      if (val == "add") {
        rightscore(uid, reviewid, mainid).then((res) => {
          this.allTopic = res.data;
          let lastId = this.allTopic[this.allTopic.length - 1].id;
          if (this.scorerecord.fullscore === undefined || this.scorerecord.fullscore === "") {
            Dialog.alert({
              title: "提示",
              message: "请作答当前题目",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              // on close
            });
          }
          if (this.scorerecord.fullscore === 0) {
            this.$dialog
              .confirm({
                title: "提示",
                message: `${this.tagname + this.mileage_name}未得分，即将结束本维度测评，是否确认？`,
                confirmButtonColor: "#fff",
                messageAlign: "left",
                getContainer: ".body",
              })
              .then(() => {
                let data = {
                  trecord_id: this.trecord_id,
                  toprecord_id: this.toprecord_id,
                  uid: uid,
                  review_id: reviewid,
                  main_id: mainid,
                  topic_id: this.questionid,
                  score: this.scorerecord.fullscore,
                  ans_record: JSON.stringify(this.setarr),
                };
                recordadd(data).then((res) => {
                  if (res.code == "1") {
                    this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                    Notify({ type: "success", message: res.msg });
                  }
                });
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
            return false
          }
          if (this.questionid == lastId) {
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
              ans_record: JSON.stringify(this.setarr),
            };
            recordadd(data).then((res) => {
              if (res.code == "1") {
                this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                Notify({ type: "success", message: res.msg });
              }
            });
          } else {
            let data = {
              trecord_id: this.trecord_id,
              toprecord_id: this.toprecord_id,
              uid: uid,
              review_id: reviewid,
              main_id: mainid,
              topic_id: this.questionid,
              score: this.scorerecord.fullscore,
              ans_record: JSON.stringify(this.setarr),
            };
            recordadd(data).then((res) => {
              if (res.code == "1") {
                let params = {
                  uid: uid,
                  review_id: reviewid,
                  main_id: mainid,
                  topic_sort: this.topic_sort,
                };
                nextTopic(params).then((res) => {
                  if (res.code == 9100) {
                    this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
                    return;
                  }
                  // return
                  if (res.data.topic_type == 1) {
                    this.$router.replace({
                      name: "addtable",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      },
                    });
                  } else if (res.data.topic_type == 2) {
                    this.$router.replace({
                      name: "choosepicture",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      },
                    });
                    Object.assign(this.$data, this.$options.data());
                    this.getlist()
                  } else if (res.data.topic_type == 3) {
                    this.$router.replace({
                      name: "addTruefalse",
                      query: {
                        prosort: this.topic_sort,
                        ass_num: this.$route.query.ass_num,
                        eva_type: this.$route.query.eva_type,
                        is_rec: res.data.is_rec,
                        trec_id: res.data.trec_id,
                        topic_id: res.data.topic_id,
                        topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                      },
                    });
                  }
                });
                Notify({ type: "success", message: res.msg });
              }
            });
          }
        });
      } else if (val == "jian") {
        let preTopicSort = this.topic_sort - 1;
        topicRecord(uid, preTopicSort, reviewid, mainid, 1).then((res) => {
          if (res.code != 1) {
            return Notify({ type: "danger", message: res.msg });
          }
          if (res.data.topic_type === "1") {
            this.$router.replace({
              name: "addtable",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
          } else if (res.data.topic_type === "2") {
            this.$router.replace({
              name: "choosepicture",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
            Object.assign(this.$data, this.$options.data());
            this.getlist()
          } else if (res.data.topic_type === "3") {
            this.$router.replace({
              name: "addTruefalse",
              query: {
                topic_sort: preTopicSort,
                type: 1,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
          }
        });
      } else if (val == "finish") {
        if (this.scorerecord.fullscore == "") {
          this.scorerecord.fullscore = 0;
        }
        let data = {
          trecord_id: this.trecord_id,
          toprecord_id: this.toprecord_id,
          uid: uid,
          review_id: reviewid,
          main_id: mainid,
          topic_id: this.questionid,
          score: this.scorerecord.fullscore,
          ans_record: JSON.stringify(this.setarr),
        };
        recordadd(data).then((res) => {
          if (res.code == "1") {
            this.nextMainConfirm(uid, reviewid, mainid, lesson_id);
            Notify({ type: "success", message: res.msg });
          }
        });
      }
    },
    // 下一维度
    nextMainConfirm(uid, reviewid, mainid, lesson_id) {
      let data = {
        uid: uid,
        review_id: reviewid,
        main_id: mainid,
        lesson_id,
      };
      finishAsses(data).then((res) => {
        Notify({ type: "success", message: res.msg });
        if (this.nextname == "最后一个维度了") {
          if (this.isend) {
            Toast.success({
              duration: 3000,
              forbidClick: true,
              message: "恭喜你，完成评估",
              onClose: () => {
                this.$router.replace({
                  name: "startpingu",
                  query: {
                    ass_num: this.$route.query.ass_num,
                    eva_type: this.$route.query.eva_type,
                  },
                }).then(() => {
                  this.$router.go(-1)
                });
              },
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "本维度已完成评估，请选择未完成的维度继续作答",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              getContainer: ".body",
            }).then(() => {
              this.$router.replace({
                name: "startpingu",
                query: {
                  ass_num: this.$route.query.ass_num,
                  eva_type: this.$route.query.eva_type,
                },
              }).then(() => {
                this.$router.go(-1)
              });
            });
          }
        } else {
          this.$dialog
            .confirm({
              title: "提示",
              message:
                "本维度的测评已结束，下一个测评维度是" +
                '<span style="color:red">' +
                this.nextname +
                "</span>",
              confirmButtonColor: "#fff",
              messageAlign: "left",
              confirmButtonText: "继续评估",
              cancelButtonText: "手动调整",
              getContainer: ".body",
            })
            .then(() => {
              settopicid(this.nextmainid);
              setltemname(this.nextname);
              setlesson_id(this.nextlessonid);
              let nextuid = getuid();
              let nextreviewid = getreviewid();
              let params = {
                uid: nextuid,
                review_id: nextreviewid,
                main_id: this.nextmainid,
                topic_sort: 0,
              };
              nextTopic(params).then((res) => {
                if (res.data.topic_type == 1) {
                  this.$router.replace({
                    name: "addtable",
                    query: {
                      ass_num: this.$route.query.ass_num,
                      eva_type: this.$route.query.eva_type,
                      is_rec: res.data.is_rec,
                      trec_id: res.data.trec_id,
                      topic_id: res.data.topic_id,
                      topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                    },
                  });
                } else if (res.data.topic_type == 2) {
                  this.$router.replace({
                    name: "choosepicture",
                    query: {
                      ass_num: this.$route.query.ass_num,
                      eva_type: this.$route.query.eva_type,
                      is_rec: res.data.is_rec,
                      trec_id: res.data.trec_id,
                      topic_id: res.data.topic_id,
                      topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                    },
                  });
                  Object.assign(this.$data, this.$options.data());
                  this.getlist()
                } else if (res.data.topic_type == 3) {
                  this.$router.replace({
                    name: "addTruefalse",
                    query: {
                      ass_num: this.$route.query.ass_num,
                      eva_type: this.$route.query.eva_type,
                      is_rec: res.data.is_rec,
                      trec_id: res.data.trec_id,
                      topic_id: res.data.topic_id,
                      topic_sort: res.data.is_rec ? res.data.topic_sort : '',
                    },
                  });
                }
              });
            })
            .catch(() => {
              this.$router.replace({
                name: "startpingu",
                query: {
                  ass_num: this.$route.query.ass_num,
                  eva_type: this.$route.query.eva_type,
                },
              }).then(() => {
                this.$router.go(-1)
              });
            });
        }
      });
    },
    onpage() {
      if (this.number > 1) {
        Dialog.confirm({
          title: "提示",
          message: "退出页面后练习题需重新作答，确定退出吗",
          confirmButtonColor: "#fff",
          messageAlign: "left",
          getContainer: ".body",
        })
          .then(() => {
            this.$router.replace({
              name: "startpingu",
              query: {
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            }).then(() => {
              this.$router.go(-1)
            });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.replace({
          name: "startpingu",
          query: {
            ass_num: this.$route.query.ass_num,
            eva_type: this.$route.query.eva_type,
          },
        }).then(() => {
          this.$router.go(-1)
        });
      }
    },
    manual(val) {
      this.auto = val;
      if (val === "手动") {
        const coppy = this.scorerecord.fullscore ? JSON.parse(JSON.stringify(this.scorerecord.fullscore)) : '';
        this.scorerecord.fullcoppy = coppy;
        this.scorerecord.fullscore = "";
        this.text = "自动";
        this.zero = false;
        this.zerofive = false;
        this.one = false;
      } else {
        this.zero = false;
        this.zerofive = false;
        this.one = false;
        this.scorerecord.fullscore = this.scorerecord.fullcoppy;
        this.text = "手动";
      }
    },
    deltablemodel() {
      this.tableshow = false;
    },
    opentable() {
      this.tableshow = !this.tableshow;
    },
    deleteitem() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "删除项目记录后会影响本题得分，确定删除吗？",
          confirmButtonColor: "fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          alert("确定");
          // on confirm
        })
        .catch(() => {
          alert("取消");
          // on cancel
        });
    },
    scoreshow() {
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.show = true;
      rightscore(uid, reviewid, mainid).then((res) => {
        this.scorearr = res.data;
      });
    },
    // 回看
    backSee(topic_sort, toprecord_id) {
      if (toprecord_id != 0) {
        let uid = getuid();
        let reviewid = getreviewid();
        let mainid = gettopicid();
        topicRecord(uid, topic_sort, reviewid, mainid, 0).then((res) => {
          if (res.code != 1) {
            return Notify({ type: "danger", message: res.msg });
          }
          if (res.data.topic_type === "1") {
            this.$router.replace({
              name: "addtable",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
          } else if (res.data.topic_type === "2") {
            this.$router.replace({
              name: "choosepicture",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
            Object.assign(this.$data, this.$options.data());
            this.getlist()
          } else if (res.data.topic_type === "3") {
            this.$router.replace({
              name: "addTruefalse",
              query: {
                topic_sort: topic_sort,
                type: 0,
                ass_num: this.$route.query.ass_num,
                eva_type: this.$route.query.eva_type,
              },
            });
          }
        });
      }
    },
    overlayTip() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "当前状态可查看，但无法编辑，点击确认即可返回维度列表",
          confirmButtonColor: "fff",
          messageAlign: "left",
          getContainer: ".body",
        })
        .then(() => {
          this.$router.replace({
            name: "startpingu",
            query: {
              ass_num: this.$route.query.ass_num,
              eva_type: this.$route.query.eva_type,
            },
          }).then(() => {
            this.$router.go(-1)
          });
        })
        .catch(() => { });
    },
    preview(index, isSample) {
      let arr = this.imgsarr.map((item) => item.url);
      this.images = isSample ? [this.src] : arr;
      this.previewIndex = index;
      this.imgPreview = true;
    }
  }
}
</script>

<style lang="less" scoped>
.body {
  min-height: 100vh;
  background: url(../src/img/bj.png) top left/100% auto no-repeat;
  padding-bottom: 0.7rem;
  button {
    border: none;
  }
  .van-nav-bar {
    background: none;
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
    .btn {
      width: 1rem;
      height: 0.34rem;
      background: #f14947;
      border-radius: 0.06rem;
      font-size: 0.14rem;
      border: none;
    }
  }
  .from {
    padding: 0 16px;
  }
  .mycard {
    // width: 95%;
    padding: 0.13rem 0.16rem;
    margin: 0 auto;
    font-size: 0.16rem;
    background: #ffffff;
    border-radius: 0.06rem;
    margin-top: 0.1rem;
    box-shadow: 0 0 0.1rem 0.01rem rgba(157, 4, 4, 0.05);
    box-sizing: border-box;
    .pingubtn {
      height: 0.34rem;
      background: #eea837;
      border-radius: 0.06rem;
      font-size: 0.16rem;
      overflow: hidden;
      float: left;
      margin-right: 0.16rem;
    }
    .text {
      color: #333333;
      line-height: 0.35rem;
      letter-spacing: 0.01rem;
    }
    .view-btns {
      display: flex;
      align-items: center;
      margin-top: 0.12rem;
      .btn {
        line-height: 0.26rem;
        background: #ffeeee;
        border-radius: 0.14rem;
        font-size: 0.13rem;
        color: #f14947;
        padding: 0 0.1rem;
        margin-right: 0.12rem;
        position: relative;
        &.active::after {
          content: "";
          display: block;
          width: 0.12rem;
          height: 0.12rem;
          background: #ffeeee;
          border-radius: 0.02rem;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
          z-index: 1;
        }
      }
    }
    .view-content {
      display: flex;
      flex-wrap: wrap;
      background: #f8f8f8;
      border-radius: 0.06rem;
      padding: 0.08rem 0.15rem;
      margin-top: 0.1rem;
      font-size: 0.13rem;
      color: #666;
      line-height: 1;
      .standard {
        margin: 0.05rem 0.3rem 0.05rem 0;
        display: flex;
        align-items: center;
        .score {
          display: inline-block;
          line-height: 0.2rem;
          background: #ffdb9f;
          border-radius: 0.1rem;
          padding: 0 0.07rem;
          font-size: 0.12rem;
          color: #674000;
          margin-right: 0.07rem;
        }
        span {
          vertical-align: sub;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .child-title {
    font-size: 0.16rem;
    color: #333333;
    line-height: 0.35rem;
    letter-spacing: 0.01rem;
    margin-top: 0.1rem;
    padding-left: 0.2rem;
  }
  .scorebtn {
    height: 0.44rem;
    position: fixed;
    right: 0;
    top: 70%;
    display: flex;
    .box {
      width: 0.44rem;
      height: 0.44rem;
      background: #ffecec;
      box-shadow: 0 0 0.06rem 0.01rem rgba(110, 8, 8, 0.23);
      &::before {
        position: absolute;
        top: 0.06rem;
        left: -0.17rem;
        content: "";
        width: 0.32rem;
        height: 0.32rem;
        background: #ffecec;
        transform: rotate(135deg);
        box-shadow: 0 0 0.06rem 0.01rem rgba(110, 8, 8, 0.23);
      }
      &::after {
        content: "分数";
        position: absolute;
        top: 0;
        left: 0;
        width: 0.44rem;
        height: 0.44rem;
        background: #ffecec;
        font-size: 0.15rem;
        color: #f14947;
        line-height: 0.44rem;
        text-align: center;
      }
    }
  }
  .scorebox {
    height: 100%;
    background: #ffffff;
    padding: 0.24rem 0.24rem 0.08rem;
    position: fixed;
    border-radius: 0.2rem 0.2rem 0 0;
    top: 0;
    right: 0;
    left: auto;
    transform: none;
  }
  .calculatescore {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.16rem;
    p {
      color: #f14947;
      font-size: 0.16rem;
      margin: 0;
      width: 0.34rem;
      &.disabled {
        color: #999;
      }
    }
    ul {
      display: flex;
      li {
        width: 0.64rem;
        height: 0.34rem;
        background: #ffffff;
        border-radius: 0.17rem;
        border: 1px solid #cccccc;
        font-size: 0.15rem;
        color: #666666;
        text-align: center;
        line-height: 0.34rem;
        margin-left: 0.2rem;
      }
      li.active {
        background: #f1b207;
        color: #fff;
        border: 1px solid #f1b207;
      }
    }
  }
  .calculatescore:last-child {
    margin-bottom: 0.4rem;
  }
  .fixedcard {
    width: 100%;
    height: 0.7rem;
    background: #ffffff;
    box-shadow: 0 0 0.06rem 0.01rem rgba(157, 4, 4, 0.16);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 55;
    box-sizing: border-box;
    padding: 0 0.2rem;
    .score {
      font-size: 0;
      span {
        display: inline-block;
        width: 0.56rem;
        height: 0.34rem;
        text-align: center;
        line-height: 0.34rem;
        background: #ffffff;
        border-radius: 0.17rem;
        border: 1px solid #cccccc;
        font-size: 0.15rem;
        color: #666666;
        margin-left: 0.1rem;
      }
      .active {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
      .active1 {
        background: #eea837;
        color: #fff;
        border-color: #eea837;
      }
    }
    .buttongroup {
      display: flex;
      div {
        width: 0.96rem;
        height: 0.34rem;
        border-radius: 0.17rem;
        opacity: 1;
        text-align: center;
        line-height: 0.34rem;
        font-size: 0.16rem;
        margin-left: 0.11rem;
      }
    }
    .item {
      flex-grow: 1;
      margin-left: 0.15rem;
      font-size: 0.16rem;
      span {
        margin-left: 0.13rem;
      }
      .myicon {
        color: #f14947;
        font-size: 0.16rem;
        margin-left: 0;
      }
    }
    .preTopic {
      height: fit-content;
      float: left;
      div {
        margin-left: 0;
      }
    }
  }
  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  /deep/ .van-dialog {
    width: 4.06rem;
    padding-bottom: 0.2rem;
    .van-dialog__header {
      text-align: left;
      padding: 0.2rem 0.24rem 0;
      color: #666;
    }
    .van-dialog__header::before {
      content: "!";
      display: inline-block;
      width: 0.18rem;
      height: 0.18rem;
      border-radius: 50%;
      text-align: center;
      font-size: 0.18rem;
      vertical-align: text-bottom;
      margin-right: 0.06rem;
      line-height: 0.2rem;
      padding: 0 0 0.01rem 0.01rem;
      background: #ffaa20;
      color: #fff;
    }
    .van-dialog__message--has-title {
      padding-top: 0.1rem;
      padding-bottom: 0.27rem;
      font-size: 0.14rem;
      line-height: 0.3rem;
    }
    .van-dialog__footer {
      justify-content: center;
      overflow: initial;
      .van-button {
        width: 0.94rem;
        height: 0.34rem;
        flex: none;
        border-radius: 0.06rem;
        margin: 0 0.13rem;
        font-size: 0.16rem;
      }
      .van-dialog__cancel {
        border: 1px solid #b2b2b2;
        color: #707070;
      }
      .van-dialog__confirm {
        background: #fa4b52;
        color: #fff;
      }
    }
    [class*="van-hairline"]::after {
      border: none;
    }
  }
  .starting {
    width: 1.2rem;
    height: 0.34rem;
    background: #f14947;
    border-radius: 0.06rem;
    text-align: center;
    font-size: 0.14rem;
    color: #fff;
    line-height: 0.34rem;
    margin: 1.7rem auto 0;
  }
  .shownumber {
    line-height: 0.32rem;
    border-radius: 0.06rem;
    border: 1px solid #ffadac;
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    .div1 {
      width: 0.9rem;
      height: 0.32rem;
      background: #ffffff;
      border-radius: 0.06rem 0 0 0.06rem;
      color: #eea837;
    }
    .div2 {
      width: 0.9rem;
      height: 0.32rem;
      background: #ffeeee;
      border-radius: 0 0.06rem 0.06rem 0;
      color: #f14947;
    }
  }
  .picturelist {
    padding: 0 0.2rem;
    margin: 0.25rem auto 0;
    ul {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      li {
        height: 1.5rem;
        margin: 0.1rem 0.05rem;
        border-radius: 0.1rem;
        overflow: hidden;
        img {
          height: 100%;
        }
        &:nth-child(even) {
          margin-top: 0.3rem;
        }
      }
    }
  }
  .sample {
    width: 1.9rem;
    margin: 0 auto;
    background: #fff8f8;
    border-radius: 0.1rem;
    border: 1px dashed #ffadac;
    text-align: center;
    padding: 0.12rem;
    box-sizing: border-box;
    img {
      width: 100%;
      border-radius: 0.1rem;
    }
    p {
      margin: 0.05rem 0 0;
      font-size: 0.14rem;
      color: #666;
    }
  }
  .btngroup {
    // width: 90%;
    margin: 0.5rem auto 0.2rem;
    .xxBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    button {
      height: 0.34rem;
      border-radius: 0.06rem;
      border: 1px solid #cccccc;
      margin: 0.07rem;
      color: #999999;
      background: #fff;
      font-size: 0.14rem;
      padding: 0 0.1rem;
    }
    .trues,
    .falses {
      background: #fff3f3;
      border: 1px solid #ff8a88;
      color: #f14947;
    }
  }
  .picbtn .buttongroup {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.3rem;
    .pb {
      width: 0.96rem;
      height: 0.34rem;
      background: #fe6765;
      border-radius: 0.17rem;
      color: #fff;
      text-align: center;
      line-height: 0.34rem;
      font-size: 0.16rem;
      margin: 0 0.15rem;
      border: 0 !important;
    }
  }
  .toovideo {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 0.3rem;
  }
}
/deep/ .van-image-preview__image {
  max-width: 60%;
  max-height: 60%;
}
/deep/ .van-image-preview__close-icon {
  color: #fff;
}
</style>
